import m from "mithril";

import css from "./index.css";

import globalState from "../../../../state";

export default {
    view() {
        const dm = globalState.theme === "darkmode";

        return m("button", {
                type  : "button",
                class : css.darkmode,
                onclick() {
                    globalState.theme = dm ? "default" : "darkmode";
                }
            },
            m("div", { class : dm ? css.circleDm : css.circle }),
            m("img", {
                src   : "https://account.staticwars.com/img/sun.svg",
                alt   : "darkmode",
                class : css.sun
            }),
            m("img", {
                src   : "https://account.staticwars.com/img/moon.svg",
                alt   : "darkmode",
                class : css.moon
            })
        );
    }
};
