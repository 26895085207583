/**
 * Same as Object.assign but work off of all top level keys instead of top object.
 * @param {object} ob1
 * @param {object} ob2
 */
export default function deepAssign(ob1, ob2) {
    for (const key in ob2) {
        if (!ob2.hasOwnProperty(key)) {
            continue;
        }

        if (typeof ob1[key] === "object" && typeof ob2[key] === "object") {
            // if both are arrays, should overwrite not merge, right????
            if (Array.isArray(ob1[key]) && Array.isArray(ob2[key])) {
                ob1[key] = ob2[key];

                continue;
            }

            Object.assign(ob1[key], ob2[key]);

            continue;
        }

        ob1[key] = ob2[key];
    }
}
