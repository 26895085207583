import m from "mithril";

import a from "../components/anchor";

import globalState from "../state";

const i18n = globalState.i18n.verify;

export default {
    view : () =>
        m(".module.pure-u-1.pure-u-md-19-24",
            globalState.temp.success ? [
                m("h3", globalState.i18n.success),

                m("p", i18n.success),

                m(a, {
                        class : "pure-button pure-button-primary",
                        href  : "/login"
                    },
                    i18n.button
                )
            ] : 
            [
                m("h3", globalState.i18n.failure),

                m("p", i18n.failure)
            ]
        )
};
