import m from "mithril";

import a from "../../../../../components/anchor";

import handleForm from "../../../../../util/handle-form";

import globalState from "../../../../../state";

const i18n = globalState.i18n.wait.totp;

export default {
    view({ children }) {
        if ("otp" in globalState.form.errors) {
            // Errors are pretty much critical and consume the otp token, so display
            return m(".module.pure-u-1.pure-u-sm-1-2",
                m("form.pure-g.pure-form", handleForm({
                        action : "/login/wait/totp",
                        method : "post"
                    }),
                    m("input[type='hidden'][name='_formName'][value='loginWait']"),

                    m(".pure-u-1",
                        m("h2", i18n.expired.header),

                        m("p", m.trust(i18n.expired.body)),

                        m(a, {
                                class : "pure-button pure-button-primary",
                                href  : "/login"
                            },
                            i18n.expired.button
                        )
                    )
                )
            );
        }

        return children;
    }
};
