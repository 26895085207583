import m         from "mithril";
import cssjoiner from "cssjoiner";

import handleForm from "../../../util/handle-form";

import globalState from "../../../state";

import css from "../2-step/index.css";

export default {
    view({ attrs }) {
        return !globalState.user.email_verified ?
            m("form[method=post]", handleForm({
                    action : `/security/email/confirm${attrs.resend ? "?resend=true" : ""}`
                }),
                m("button[type=submit]", {
                        class : cssjoiner(
                            [ attrs.button, `pure-button pure-button-primary ${css.buttonLink}` ]
                        )
                    },
                    // todo: Why button > a?
                    m(attrs.button ? "span" : "a",
                        attrs.text || globalState.i18n.verifyEmail
                    )
                )
            ) :
            null;
    }
};
