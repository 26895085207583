import m from "mithril";

import linkAccounts from "../../../components/modals/link-accounts";

import globalState from "../../../state";

import css from "../index.css";

export default {
    view() {
        const gcI18n = globalState.i18n.gameContent;

        return !globalState.hasGw1 && !globalState.isSteamUser && !globalState.isEpicUser ?
            m("div.module.pure-u-1.pure-u-md-19-24", { class : css.module },
                m("button", {
                        class       : css.openLink,
                        "data-test" : "linkGw1",
                        onclick(e) {
                            e.preventDefault();
                            globalState.modal.display(linkAccounts);
                        }
                    },
                    m("h3", { class : css.gcHeading },
                        gcI18n.openLink
                    )
                )
            ) :
            null;
    }
};
