import m from "mithril";

import isInternal from "../../../util/is-internal";
import handleForm from "../../../util/handle-form";

import globalState from "../../../state";

import css from "./index.css";

const i18n = globalState.i18n.security.settings;

const location = {
    view({ attrs }) {
        if (isInternal(attrs.network.address)) {
            return i18n.internal;
        }

        if (!attrs.network.location.country) {
            return i18n.unknown;
        }

        const loc = [ "city", "region", "country" ]
            .map(attr => attrs.network.location[attr])
            .filter(item => item && typeof item === "string" && isNaN(parseInt(item, 10)))
            .join(", ");

        return decodeURIComponent(loc);
    }
};

const showRemove = {
    view({ attrs }) {
        const action = attrs.allowlist ? "allowlist" : "login";

        if (attrs.network.current) {
            return;
        }

        return m("form[method='post']", handleForm({
                action : `/security/${action}/remove`
            }),
            m("input[type='hidden']", {
                name  : "ip",
                value : attrs.network.address
            }),

            m("input[type='hidden']", {
                name  : "prefix",
                value : attrs.network.network_prefix
            }),

            m("input[type='hidden']", {
                name  : "location",
                value : attrs.network.session
            }),

            m("input.modLink[type='submit']", {
                class : css.noButtonStyle,
                value : attrs.allowlist ?
                    i18n.remove :
                    i18n.disconnect
            })
        );
    }
};

export default {
    view : ({ attrs }) =>
        m("div.modFieldStack",
            m(location, attrs),

            m(showRemove, attrs),

            m("div.modVal",
                attrs.allowlist ?
                    `${attrs.network.begin_address} - ${attrs.network.end_address}` :
                   attrs.network.address
            )
        )
};
