"use strict";

const steamRegex = /@steam$/;

/**
 * Extract Steam ID from Anet login name
 * @param {string} login - Account login (email or {steamid}@steam)
 * @returns {string} Steam ID (too big for JS number)
 */
module.exports = function getSteamId(login) {
    return login.replace(steamRegex, "");
};
