import m from "mithril";

// Used for logins w/ redirects & GMWeb
export default {
    view : () => {
        return [
            "game_code",
            "redirect_uri",
            "return_to", // zendesk JWT login
            "fakeip"
        ]
        .map(param => (
            m.route.param(param) ?
                m("input[type='hidden']", {
                    name  : param,
                    value : m.route.param(param)
                }) :
                null
        ));
    }
};
