import m from "mithril";

import globalState from "../../../../../state";

import css from "../../../index.css";

const i18n = globalState.i18n.wait.tfaside;

export default {
    view : () =>
        m(".pure-u-md-1-3.pure-hidden-sm.pure-hidden-tn", { class : css.totpImgs },
            m("div",
                m("img", { src : "https://account.staticwars.com/img/totp.png", alt : "" })
            ),

            m("h4", i18n.header),

            m("p", i18n.body1),

            m("p", i18n.body2),

            m("a", { href : globalState.get("urls.authenticator") }, i18n.link)
        )
};
