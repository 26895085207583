import m from "mithril";

import emailVerify from "../../security/email/email-verify-link";

import globalState from "../../../state";

import css from "../index.css";

const pageI18n = globalState.i18n.overview;

export default {
    view() {
        return m("div.pure-u-1.pure-u-md-1-4", { class : css.sideColumn },
            m("div.pure-u-1.pure-u-sm-20-24.pure-u-md-1", { class : css.module },
                m("h3", pageI18n.personal.title),

                // personal info
                m("div.modFieldStack",
                    pageI18n.personal.email,

                    !globalState.isSteamUser && !globalState.isEpicUser ?
                        m("div.modLink",
                            m(emailVerify)
                        ) :
                        null,

                    m("div.modVal",
                        decodeURIComponent(globalState.user.login_name)
                    )
                ),

                m("div.modFieldStack",
                    pageI18n.personal.display,
                    m("div.modVal",
                        decodeURIComponent(globalState.user.user_name)
                    )
                ),

                m("div.modFieldStack",
                    pageI18n.personal.region,
                    m("div.modVal",
                        pageI18n.personal[`region-${globalState.user.user_center}`] || ""
                    )
                )
            )
        );
    }
};
