import form from "Form";

import schemas     from "../preload/schemas";
import globalState from "../state";

let setupQueue = [],
    ready;

function render({ dom, callback }) {
    if (!globalState.features.captcha) {
        return;
    }

    grecaptcha.render(dom, {
        sitekey : globalState.captcha.recaptchaKey,
        callback
    });
}

if (window.state.features.captcha) {
    // called when recaptcha lib is loaded and ready
    window.recaptchaCallback = () => {
        ready = true;

        setupQueue.forEach(render);

        setupQueue = [];
    };
}

/**
 * Set up recaptchas
 * @param {object} opts - recaptcha opts
 * @param {object} opts.dom - dom element to render recaptcha into
 * @param {function} opts.callback - fn to call when recaptcha is complete
 */
export function initRecaptcha(opts) {
    // if this fn is called before recaptcha is ready, add them to a queue to init when lib is loaded
    if (!ready) {
        setupQueue.push(opts);

        return;
    }

    render(opts);
}

export class CheckEmail {
    constructor({ fields, schema, type } = {}) {
        this.timeout = null;
        this.fields  = fields;
        this.schema  = schema;
        this.type    = type || "register";
    }

    check({ fields, schema } = {}) {
        if (!globalState.features.captcha) {
            return Promise.resolve();
        }

        fields = fields || this.fields;
        schema = schema || this.schema || schemas.email;

        const result = form.validate(fields, schema);

        if (!result.valid) {
            return Promise.reject();
        }

        // so it's easier to find when searching: "/ws/email-login"
        return m.request({
            url    : `/ws/email-${this.type}?${m.buildQueryString({ _t : Date.now() })}`,
            method : "POST",
            data   : fields
        });
    }
}
