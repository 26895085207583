"use strict";

const epicRegex = /@epic$/;

/**
 * Extract Epic ID from Anet login name
 * @param {string} login - Account login (email or {epicid}@steam)
 * @returns {string} Epic ID
 */
module.exports = function getEpicId(login) {
    return login.replace(epicRegex, "");
};
