import m from "mithril";

import globalState from "../../../../state";

import ga from "../../../../util/ga";

import css from "../../index.css";

class RegTheme {
    constructor(name) {
        this.name     = name;
        this.i18n     = globalState.i18n.register[this.name];
        this.tagline1 = "";
        this.tagline2 = "fancy";

        const self = this;

        this.flourish   = { view : () => m("div", { class : css.flourish }) };
        this.tagline    = {
            view : () =>
                m(".serifCaps", { class : css.tagline },
                    m("div", { class : css[this.tagline1] }, m.trust(this.i18n.tagline1)),
                    m("div", { class : css[this.tagline2] }, m.trust(this.i18n.tagline2))
                )
        };
        this.lookingFor = {
            view : () =>
                m("p", { class : css.looking },

                    self.i18n.lookingFor,

                    m("a", {
                            "data-test" : "regP4f",
                            href        : "/register?alt=gw2",

                            onclick(e) {
                                e.preventDefault();

                                ga({
                                    event         : "gtm-p4f",
                                    eventCategory : "pageview",
                                    eventAction   : "p4f-click",
                                    eventLabel    : "p4f-reg"
                                });

                                m.route.set(e.currentTarget.href);
                            }
                        },
                        globalState.i18n.register.click
                    )
                )
        };
    }
}

class pof extends RegTheme {
    constructor() {
        super("pof");
        this.flourish.view = () => null;
    }
}

class gw2 extends RegTheme {
    constructor() {
        super("gw2");
        this.tagline1        = "fancy";
        this.tagline2        = "";
        this.lookingFor.view = () => null;
        this.p4f             = true;
    }
}

class pgw2 extends RegTheme {
    constructor() {
        super("pgw2");
        this.tagline1        = "fancy";
        this.tagline2        = "";
        this.lookingFor.view = () => null;
        this.p4f             = true;
    }
}

class none extends RegTheme {
    constructor() {
        super("gw2");
    }
}

// When adding a theme it also needs to be added to this object
// so that it can be dynamically instantiated
const regThemes = {
    pof,
    hot : pof,
    gw2 : pgw2,
    none,
    pgw2,
    p4f : pgw2
};

// todo: move default theme to config
export default function getRegTheme(regTheme = "pgw2") {
    // default to gw2 as our theme
    if (!(regTheme in regThemes)) {
        regTheme = "pgw2";
    }

    // dynamic instantiation, it's not wonderful
    return new regThemes[regTheme]();
}
