export default class Form {
    constructor() {
        this.data           = {};
        this.errors         = {};
        this.valid          = true;
        this.errorMessage   = "";
        this.debounceErrors = {};
    }

    getErrorMessage() {
        return this.errorMessage;
    }

    setErrorMessage(msg) {
        this.errorMessage = msg;
    }

    clearErrorMessage() {
        this.errorMessage = "";
    }

    clearError() {
        this.errorMessage = "";
        // don't want input in error state when re-opening
        this.errors = {};
    }

    setForm({ data = this.data, errors = this.errors, valid = this.valid }) {
        this.data   = data;
        this.errors = errors;
        this.valid  = valid;
    }

    getFormValue(key) {
        return this.data[key];
    }
}
