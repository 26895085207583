import m from "mithril";

import modalContainer from "../components/modal-container";
import successModal   from "../success";

import formField from "../../form-field";

import handleForm  from "../../../util/handle-form";
import globalState from "../../../state";

import schemas from "../../../preload/schemas";

const { i18n } = globalState;

const schemaName = "change-displayname";

schemas[schemaName].displayname.push(o => o._value !== decodeURIComponent(globalState.user.user_name).split(".")[0]);

export default {
    view() {
        if (globalState.postSuccess) {
            return m(successModal, {
                i18n : {
                    header : i18n.forms["change-displayname"].success
                }
            });
        }

        return m(modalContainer,
                m("h3", i18n.forms["change-displayname"].heading),
                m("p", i18n.forms["change-displayname"].subhead),
                m("form.pure-form[method=post][action=/modals/change-displayname][data-test=displayname-modal]",
                    handleForm(),
                    m(`input[type=hidden][name=_formName][value=${schemaName}]`),
                    m(".pure-u1",
                        m(formField, {
                            name         : "displayname",
                            labelClass   : "pure-u-1 pure-u-md-17-24",
                            required     : true,
                            type         : "text",
                            strings      : i18n.forms["change-displayname"],
                            autofocus    : true,
                            autocomplete : false
                        }),
                    ),
                    m("span",
                        m("button[type=submit][data-test=displayname-submit].pure-button.pure-button-primary",
                            i18n.submit
                        )
                    )
                )
        );
    }
};
