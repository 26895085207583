import m from "mithril";

import globalState from "../../../state";

import css from "../index.css";

const i18n = globalState.i18n.unsubscribe;

export default {
    view : () =>
        m("div",
            m("h2", { class : css.unsubError }, i18n.error.header),

            m("p", { class : css.unsubQuestion }, i18n.error.req),
            
            m("p", { class : css.unsubQuestion }, m.trust(i18n.error.support))
        )
};
