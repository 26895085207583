import m             from "mithril";
import globalState   from "../../../state";
import formContainer from "../components/form-container";

const i18n     = globalState.i18n.apikeys.edit;
const formI18n = globalState.i18n.forms.apikeys.edit;

export default {
    view() {
        const id = m.route.param("id");
        // .key comes form loader when deeplinked or refreshing
        // otherwise all key data is already on globalState
        const key = globalState.key || globalState.keys.find(k => k.id === id);

        return m(formContainer, {
            action : `/applications/${id}/edit`,
            i18n,
            formI18n,
            value  : decodeURIComponent(key.name)
        });
    }
};
