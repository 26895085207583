import m from "mithril";

import handleForm from "../../../util/handle-form";

import modalContainer from "../components/modal-container";

import successModal from "../success";

import globalState from "../../../state";

const { i18n } = globalState;

export default {
    view(vnode) {
        const { name, field } = vnode.attrs;

        if (globalState.postSuccess) {
            return m(successModal, {
                i18n : {
                    header : globalState.i18n.success
                }
            });
        }

        return m(modalContainer,
            m("h3", i18n.forms[name].confirm.title),

            m(`form.pure-form[method=post][action=/modals/${name}]`,
                handleForm(),
                m("p", i18n.forms[name].confirm.desc),

                // the value we want to delete, it must be the same name as the field to edit it
                m("input[type=hidden]", { name : field }),

                m("span",
                    m("button[type=submit][data-test=confirm].pure-button.pure-button-primary",
                        i18n.forms.confirm
                    ),

                    m("button[data-type=secondary][data-test=cancel].pure-button",
                        {
                            onclick() {
                                globalState.modal.displayPrev();
                            }
                        },
                        i18n.forms.cancel
                    )
                )
            )
        );
    }
};
