import m from "mithril";

import modalContainer from "../components/modal-container";

import successModal from "../success";

import handleForm from "../../../util/handle-form";

import formField      from "../../form-field";
import passwordInputs from "../../password-inputs";

import globalState from "../../../state";

const pwI18n = globalState.i18n.changepw;

export default {
    view() {
        if (globalState.postSuccess) {
            return m(successModal, {
                i18n : {
                    header : pwI18n.success
                }
            });
        }

        return m(modalContainer,
            m("h3", pwI18n.title),
            m("form.pure-form[method=post][action=/modals/change-pw]",
                handleForm(),
                m("input[type=hidden][name=_formName][value=change-pw]"),

                m(formField, {
                    name         : "old",
                    labelClass   : "pure-u-1 pure-u-md-17-24",
                    type         : "password",
                    strings      : globalState.i18n.forms.changepw.old,
                    autofocus    : true,
                    autocomplete : false
                }),

                m(passwordInputs),

                m("button[type=submit].pure-button.pure-button-primary",
                    globalState.i18n.submit
                )
            )
        );
    }
};
