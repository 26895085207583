import m from "mithril";

import leftColumn   from "./components/left-column";
import middleColumn from "./components/middle-column";
import rightColumn  from "./components/right-column";

import css from "./index.css";

export default {
    view() {
        return m("div.pure-g[data-test=overviewContainer]", { class : css.overview },
            m(leftColumn),

            m(middleColumn),

            m(rightColumn)
        );
    }
};
