import m from "mithril";

import globalState from "../../../../../state";

const i18n = globalState.i18n.email.verify;

// This is basically copying the security/email component.
export default {
    view() {
        return m(".module.pure-u-1.pure-u-md-1-2[data-test=verify-email]",
            m("div",
                m("h3", i18n.title),

                m("p", i18n.body1)
            )
        );
    }
};
