import m                from "mithril";
import animationResolve from "animation-resolve";

import globalState from "../../../../state";

import css from "./index.css";

function escClose(e) {
    if (e.key === "Escape") {
        globalState.modal.close();
        globalState.form.clearError();

        m.redraw();
    }
}

export default {
    view({ state }) {
        return globalState.modal.currentModal ?
            m("div", {
                    class : css.overlayIn,
                    oninit() {
                        window.addEventListener("keydown", escClose);
                    },
                    onbeforeremove({ dom }) {
                        const { contentDom } = state;

                        window.removeEventListener("keydown", escClose);

                        return Promise.all([
                            animationResolve(dom, css.overlayOut),
                            animationResolve(contentDom, css.overlayContentOut)
                        ]);
                    },
                    onclick : e => {
                        if (e.target === e.currentTarget) {
                            globalState.modal.close();
                            globalState.form.clearError();

                            globalState.postSuccess = false;
                        }
                    }
                },

                m("div", {
                        class : css.overlayContentIn,
                        oncreate({ dom }) {
                            // reference for animate out
                            state.contentDom = dom;
                        }
                    },

                    m(globalState.modal.currentModal, globalState.modal.currentAttrs)
                )
            ) :
            null;
    }
};
