import m         from "mithril";
import cssjoiner from "cssjoiner";

import globalState from "../../../state";

import ga from "../../../util/ga";

import css from "../index.css";

const i18n = globalState.i18n.unsubscribe;

const viewState = {
    radios : {}
};

function stopPropagation(e) {
    e.stopPropagation();
}

export default {
    view : () =>
        m("div",
            m("h2", { class : css.unsubSuccess }, i18n.success.header),

            m("div", { class : css.formThanks },
                m("div", {
                        class : cssjoiner(
                            css.unsubForm,
                            [ viewState.replied, css.unsubFormHide ]
                        )
                    },
                    m("p", { class : css.unsubQuestion }, m.trust(i18n.success.question)),

                    m("form.pure-form", {
                            // Skip form handling stuff for this
                            onsubmit(e) {
                                e.preventDefault();

                                if (viewState.replied) {
                                    return;
                                }

                                Object.keys(viewState.radios).forEach(idx => {
                                    if (!viewState.radios[idx]) {
                                        return;
                                    }

                                    viewState.replied = true;

                                    ga({
                                        event         : "gtm-unsubReason",
                                        eventCategory : "email",
                                        eventAction   : "unsubReason",
                                        eventLabel    : i18n.unsub.reasons[idx]
                                    });
                                });
                            },
                            oninput  : stopPropagation,
                            onchange : stopPropagation
                        },
                        m("div", { class : css.unsubAnswers },
                            Object.keys(i18n.unsub.reasons).map(idx =>
                                m("label.field.pure-u-1",
                                    m("input[type=checkbox].pure-u", {
                                        onchange(e) {
                                            viewState.radios[idx] = e.currentTarget.checked;
                                        }
                                    }),

                                    m(".labelText.pure-u", i18n.unsub.reasons[idx])
                                )
                            )
                        ),

                        m("button[type=submit].pure-button.pure-button-primary", i18n.unsub.submit)
                    )
                ),

                m("p", {
                    class : cssjoiner(
                        css.thanks,
                        [ viewState.replied, css.thanksShow ]
                    )
                }, i18n.thanks)
            ),

            m("p", m.trust(i18n.success.resub))
        )
};
