import m from "mithril";

import a from "../../../components/anchor";

import globalState from "../../../state";

import css from "../index.css";

export default {
    view() {
        const i18n = globalState.i18n["password-reset"];

        return m("div", { class : css.passwordResetModule },
            m("h3", globalState.i18n.failure),

            m("p", m.trust(i18n.failure)),

            m("a.pure-button.pure-button-primary[href=/password-reset]", {
                    class  : css.tryAgain,
                    config : m.route
                },
                i18n.tryagain
            ),

            m(a, {
                    class : [ "pure-u", css.return ].join(" "),
                    href  : "/login"
                },
                i18n.returnlink
            )
        );
    }
};
