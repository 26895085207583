import m from "mithril";

import globalState from "../../../state";

import gw2Downloads from "./gw2-downloads";

import css from "../index.css";

export default {
    view() {
        const gcI18n = globalState.i18n.gameContent;
        const p4f    = !globalState.get("gw2Features.full.owned");
        const noGw2  = !globalState.gw2Features && globalState.features;

        return m("div.pure-u-1.pure-u-md-19-24", { class : css.expandedModule },
            m("div", { class : css.gameBd },

                // Game header
                m("h3", { class : css.gcHeading },
                    gcI18n[p4f ? "p4f" : "gw2"].title
                ),

                // links
                m("div", { class : css.gameLinks },
                    // Upgrade
                    globalState.missingExpansions.length ?
                        m("a", {
                                class  : css.upIcon,
                                href   : noGw2 ? "/register?alt=gw2" : globalState.getBuyLink(),
                                config : noGw2 && m.route,
                                target : !noGw2 && "_blank",
                                rel    : !noGw2 && "noopener noreferrer"
                            },
                            noGw2 ? globalState.i18n.register.link : gcI18n.upgrade
                        ) :
                        null,

                    // Visit Site
                    m("a", {
                            class : css.siteIcon,
                            href  : gcI18n.gw2.url
                        },
                        gcI18n.site
                    )
                ),

                // client download links
                globalState.gameList.length ?
                    m(gw2Downloads) :
                    null
            )
        );
    }
};
