import m         from "mithril";
import cssjoiner from "cssjoiner";

import css from "./index.css";

export default {
    view({ attrs, children }) {
        const { style } = attrs;

        return m("div", { class : cssjoiner(css.tooltip, [ style, style ]) },
            children
        );
    }
};
