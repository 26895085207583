import m from "mithril";

import a       from "../../../components/anchor";
import addCode from "../../../components/modals/add-code";

import upgrade from "./upgrade";

import globalState from "../../../state";

import css from "../index.css";

const pageI18n = globalState.i18n.overview;

export default {
    view() {
        return m("div.pure-u-1.pure-u-md-1-4", { class : css.sideColumn },
            m("div.pure-u-1.pure-u-md-1.pure-u-sm-20-24", { class : css.module },
                m("h3", pageI18n.links.title),

                m("nav", { class : css.links },
                    !globalState.isSteamUser && !globalState.isEpicUser ?
                        [
                            m(a, { href : "/content", }, pageI18n.links.link1),
                            m("a", { href : globalState.get("urls.securingAccount") },
                                pageI18n.links.link2
                            )
                        ] :
                        null,

                    m("a", { href : globalState.get("urls.accountHelp") },
                        pageI18n.links.link4
                    )
                ),

                m("button.pure-button.pure-button-primary.pure-u", {
                        class       : css.codeBtn,
                        "data-test" : "openRedeemCode",
                        onclick(e) {
                            e.preventDefault();
                            globalState.modal.display(addCode);
                        }
                    },
                    pageI18n.links.button
                )
            ),

            m(upgrade)
        );
    }
};
