import m from "mithril";

import a from "../../../components/anchor";

import stepContainer from "../2-step/step-container";

import emailVerifyLink from "./email-verify-link";

import globalState from "../../../state";

import css from "../2-step/index.css";

const i18n = globalState.i18n.twostep.signup.email;

export default {
    view : () =>
        m(stepContainer,
            m.route.param("resend") && !globalState.errorMessage ?
                m("div", { class : css.resent }, i18n.resent) :
                null,

            m("p", { class : css.stepInfo }, i18n.info),

            m("h3", i18n.title),

            m("p", i18n.body1),

            m("p", i18n.body2),

            m("span", i18n.resend.text),

            m(emailVerifyLink, { 
                resend : true, 
                text   : i18n.resend.link 
            }),

            m("div", { class : css.stepFt },
                m(a, {
                        href  : "/security/settings",
                        class : "pure-button pure-button-primary"
                    },
                    globalState.i18n.twostep.finish
                )
            )
        )
};
