import m         from "mithril";
import cssjoiner from "cssjoiner";

import loadingSpinner from "../loading-spinner";

import modalOverlay from "./components/modal-overlay";
import footer       from "./components/footer";
import header       from "./components/header";
import subheader    from "./components/subheader";
import nav          from "./components/nav";

import globalState from "../../state";

import css from "./index.css";

export default {
    view({ children }) {
        const regTheme   = globalState.get("regTheme.name") || "";
        const errDetails = globalState.devReason || globalState.errorMessage.message;

        return [
            m("div", { class : cssjoiner(globalState.containerClass, regTheme, css.wrap) },
                m(header),

                m(subheader),

                // Sticky error bar
                globalState.errorMessage ?
                    m("div", {
                            class       : css.errorBar,
                            "data-test" : "errorBar"
                        },
                        m("div", {
                            class       : css.errorText,
                            "data-test" : "errorText"
                        },
                            m.trust(globalState.errorMessage.errMsg || globalState.errorMessage)
                        ),
                        errDetails && m("pre", JSON.stringify(JSON.parse(errDetails), null, 4))
                    ) :
                    null,

                m(nav),

                m("div.body",
                    globalState.loading ?
                        m(loadingSpinner) :
                        m("div", {
                                class       : css.page,
                                "data-test" : "page"
                            },
                            children
                        )
                ),

                m(footer)
            ),

            m(modalOverlay)
        ];
    }
};
