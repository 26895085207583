import m from "mithril";

import globalState from "../../state";

import getRegTheme from "./components/regTheme";
import video       from "./components/video";
import form        from "./components/form";

import css from "./index.css";

function getVideo() {
    if (!globalState.videos) {
        return;
    }

    const videos = globalState.videos[globalState.regTheme.name] || {};
    let vidId    = videos[globalState.lang];

    if (globalState.lang === "en" && globalState.rating !== "esrb" && videos["en-eu"]) {
        vidId = videos["en-eu"];
    }

    return vidId;
}

export default {
    title       : globalState.i18n.header.registration.title,
    description : globalState.i18n.header.registration.description,

    onmatch(args) {
        globalState.regTheme = getRegTheme(args.alt);
    },

    view() {
        const { regTheme } = globalState;
        const i18n         = regTheme.i18n;
        const vidId        = getVideo();

        return m("div",
            m("div", { class : css.vidCol },
                m(regTheme.tagline),

                m(regTheme.flourish),

                m(video, { vidId }),

                m("div", { class : css.vidBlurb },
                    Object.keys(i18n.blurb).map(key =>
                        m("p", m.trust(i18n.blurb[key]))
                    )
                ),

                m(regTheme.lookingFor)
            ),

            m(form, { regTheme })
        );
    }
};
