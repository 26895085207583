import m from "mithril";

import vnodeSub          from "../../../components/vnode-sub";
import a                 from "../../../components/anchor";
import changePw          from "../../../components/modals/change-pw";
import changeAccountName from "../../../components/modals/change-account-name";


import emailVerify from "../email/email-verify-link";
import securityNav from "../security-nav";

import cNetwork from "./network";

import globalState from "../../../state";

import css from "./index.css";


const i18n = globalState.i18n.security.settings;

const linktext = {
    view : () => m(a, { href : globalState.twoStepUrl() }, i18n.secureme.editLink)
};

export default {
    view() {
        const authType          = globalState.auth_type;
        const alreadySecured    = authType === "sms" || authType === "totp";
        const dragonSrc         = globalState.theme === "darkmode" ? "https://account.staticwars.com/img/dragon-dm.jpg" : "https://account.staticwars.com/img/dragon.jpg";
        const emailChangeLocked = globalState.appData.locks.email;

        return m("div[data-test=securityContainer]", { class : css.security },
            m(securityNav),

            m("div.pure-g",
                m("div.pure-u-sm-20-24.pure-u-md-1-3.pure-u-1.leftCol",
                    m("div.pure-u-1", { class : [ css.securityModule, css.creds ].join(" ") },
                        m("h3", i18n.creds), // credentials

                        m("div.modFieldStack",
                            i18n.accname,

                            !emailChangeLocked ? m("button", {
                                class       : "modLink",
                                "data-test" : "editAccountName",
                                onclick(e) {
                                    e.preventDefault();
                                    globalState.modal.display(changeAccountName);
                                }
                            }, i18n.edit) : null,

                            m("div.modLink", m(emailVerify)),

                            m("div.modVal[data-test='account-name']", decodeURIComponent(globalState.user.login_name))
                        ),

                        m("div.modFieldStack",
                            i18n.password,

                            m("button", {
                                class : "modLink",
                                onclick(e) {
                                    e.preventDefault();
                                    globalState.modal.display(changePw);
                                }
                            },
                                i18n.edit
                            )
                        ),

                        m("div.modFieldStack",
                            i18n.twostep,

                            m(a, {
                                class : "modLink",
                                href  : globalState.twoStepUrl()
                            },
                                i18n.edit
                            ),

                            m("div.modVal", i18n.type[authType || "none"])
                        )
                    )
                ),

                m("div.pure-u-1.pure-u-sm-20-24.pure-u-md-2-3", {
                    class : [ css.secureMe, css.securityModule ].join(" ")
                },
                    m("h3",
                        alreadySecured ?
                            i18n.secureme.secured.head :
                            i18n.secure.label
                    ),

                    m("div.pure-u-1",
                        alreadySecured ?
                            i18n.secureme.secured.body1[authType] :
                            i18n.secureme.body3
                    ),

                    m("div.pure-u-1.pure-u-md-1-2", { class : css.secLeftCol },
                        m("div", { class : css.subHead },
                            alreadySecured ?
                                i18n.secureme.secured.subhead :
                                i18n.secureme.head1
                        ),

                        m("p",
                            alreadySecured ?
                                m(vnodeSub, {
                                    str   : i18n.secureme.secured.body2,
                                    inner : { editLink : linktext }
                                }) :
                                m.trust(i18n.secureme.body1)
                        )
                    ),

                    m("div.pure-u-1.pure-u-md-1-2", { class : css.secRightCol },
                        m("div", { class : css.subHead }, i18n.secureme.head2),

                        m.trust(i18n.secureme.body2)
                    ),

                    !alreadySecured && globalState.needsMini ?
                        m("div.pure-u-1.pure-u-md-1-2", { class : css.miniDragon },
                            m("div.pure-u-1", { class : css.miniBox },
                                m("img", { src : dragonSrc, alt : "" }),

                                m("div", { class : css.miniTxt }, globalState.i18n.twostep.signup.sms.done.mini)
                            )
                        ) :
                        null,

                    !alreadySecured ?
                        m(a, {
                            class : "pure-button pure-button-primary pure-u-1 pure-u-md-11-24",
                            href  : globalState.twoStepUrl()
                        },
                            i18n.getgoing
                        ) :
                        null
                ),

                m("div.pure-u-sm-20-24.pure-u-md-1-2.pure-u-1.leftCol",
                    m("div.pure-u-1", { class : css.securityModule },
                        m("h3", i18n.authed_networks),

                        globalState.allowlist.map(network =>
                            m(cNetwork, {
                                network   : network,
                                allowlist : true
                            })
                        )
                    )
                ),

                m("div.pure-u-1.pure-u-sm-20-24.pure-u-md-1-2", { class : css.securityModule },
                    m("h3", i18n.current_logins),

                    globalState.logins.map(network =>
                        m(cNetwork, {
                            network : {
                                location : network.details,
                                session  : network.session,
                                current  : network.current,
                                address  : network.details.address
                            }
                        })
                    )
                )
            )
        );
    }
};
