import m       from "mithril";
import { sub } from "template";

import a from "../../../../components/anchor";

import preserveParams from "../../../../util/preserve-params";
import handleForm     from "../../../../util/handle-form";

import stepContainer    from "../step-container";
import verificationForm from "../link/verification-form";

import globalState from "../../../../state";

import css from "../index.css";

const i18n = globalState.i18n.twostep.signup.sms.link;

export default {
    view() {
        const phone       = m.route.param("phone");
        const countryCode = m.route.param("countryCode");
        const type        = m.route.param("type");

        return m(stepContainer,
            m.route.param("invalid") ?
                m("div", { class : css.expiredPrompt },
                    m("h3", i18n.invalid),

                    m("p", i18n["invalid-text"]),

                    m(a, {
                            class : "pure-button pure-button-primary",
                            href  : preserveParams("/security/2-step", [ "alt", "phone", "type" ])
                        },
                        i18n.tryagain
                    )
                ) :
                null,

            m.route.param("resend") && !globalState.errorMessage ?
                m("div", { class : css.resent }, i18n.resent) :
                null,

            m("p", { class : css.stepInfo }, i18n.info),

            m("h3", i18n.title),

            m("p", sub(i18n.body1, { phone : countryCode + phone })),

            m(verificationForm, { linkType : "sms" }),

            m("div", { class : css.smsResend },
                m("form[method=post]", handleForm({
                        action : preserveParams("/security/2-step", "alt")
                    }),
                    m("input[type=hidden][name=_formName][value=sms-verify]"),

                    m("input[type=hidden][name=phone]", { value : phone }),

                    m("input[type=hidden][name=countryCode]", { value : countryCode }),

                    m("input[type=hidden][name=type]", { value : type }),

                    m("input[type=hidden][name=resend][value=true]"),

                    i18n.resend.text,

                    m("button[type=submit]", { class : css.buttonLink },
                        i18n.resend.link
                    )
                )
            ),

            m("div", { class : css.smsBack },
                i18n.goback.text,

                m(a, {
                        class : css.resend,
                        href  : preserveParams("/security/2-step", "alt")
                    },
                    i18n.goback.link
                )
            )
        );
    }
};
