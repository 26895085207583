import globalState from "../../../state";

import formContainer from "../components/form-container";

import css from "../index.css";

const i18n     = globalState.i18n.apikeys.create;
const formI18n = globalState.i18n.forms.apikeys.create;

export default {
    view : () =>
        m(formContainer, {
                name   : "createKey", // this gets appended with "Form" for test selector
                action : "/applications/create",
                i18n,
                formI18n
            },
            m("ul", { class : css.edit }, (globalState.scopes || []).map(scope => {
                const permission = `permission-${scope}`;

                return m("li", { class : css.permission },
                    m("input", Object.assign({
                            id    : permission,
                            name  : "permissions[]",
                            type  : "checkbox",
                            value : scope
                        },
                        // The "account" scope is treated special -- it must always be checked.
                        scope === "account" ?
                            {
                                checked  : "checked",
                                readonly : "",
                                onclick  : () => false
                            } : null
                    )),

                    m("label", {
                        class : css.permissionName,
                        for   : permission
                    }, scope),

                    m("div",  { class : css.permissionDesc }, globalState.i18n.apikeys.scopes[scope])
                );
            }))
        )
};
