/**
 * Modal management interface
 */
export default {
    currentModal  : null,
    currentAttrs  : {},
    previousModal : null,
    previousAttrs : {},

    clone(val) {
        return Object.assign({}, val);
    },

    /**
     * Display modal controller
     * @param {Object} modal - mithril modal component
     * @param {Object} attrs - passed to modal as attrs
     */
    display(modal, attrs) {
        this.previousModal = this.clone(this.currentModal);
        this.previousAttrs = this.clone(this.currentAttrs);
        this.currentModal  = modal;
        this.currentAttrs  = attrs;
    },

    /**
     * Return to previous modal
     */
    displayPrev() {
        this.currentModal = this.clone(this.previousModal);
        this.currentAttrs = this.clone(this.previousAttrs);
    },

    /**
     * Closes current modal
     */
    close() {
        this.previousModal = null;
        this.previousAttrs = {};
        this.currentModal  = null;
        this.currentAttrs  = {};
    }
};
