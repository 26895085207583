import m from "mithril";

import globalState from "../../../state";

import a from "../../../components/anchor";

const i18n = globalState.i18n.allowlist.add;

export default {
    view : () =>
        m("div.module.pure-u-1.pure-u-sm-1-2",
            m("h2", i18n.success),

            m("p", i18n.result.success),

            m(a, {
                href  : "/login",
                class : "pure-button pure-button-primary"

            }, globalState.i18n.login.link)
        )
};
