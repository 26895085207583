import m from "mithril";

import social from "../../components/social";

import gamesight from "../../util/gamesight";

import globalState from "../../state";

import css from "./index.css";

const i18n = globalState.i18n.subscribed;

export default {
    oninit() {
        gamesight("send", "account_anet_doi");
    },

    view : () =>
        m("div", { class : css.subContent },

            m("h2", { class : css.success }, i18n.header),
            m("p", i18n.info),

            m("a", {
                    class       : css.eod,
                    href        : globalState.getBuyLink("eodc"),
                    "data-test" : "buyLink"
                },

                m("img", {
                    class : css.mobImg,
                    src   : "https://account.staticwars.com/pages/subscribed/img/eod-mobile.jpg",
                    alt   : ""
                }),

                m("div",
                    m("p", m.trust(i18n.eodAd)),
                    m("span", { class : css.button }, i18n.buyNow)
                )
            ),

            m(social)
        )
};
