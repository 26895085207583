import m from "mithril";

import globalState from "../../../state";

import twitchDrops from "./components/twitch-drops";

import css from "../index.css";

export default {
    view() {
        return m("div.module.pure-u-1", { class : css.module },
            m("h3", globalState.i18n.oauth2.accountLinking),

            m("ul",
                m("li", m(twitchDrops))
            )
        );
    }
};
