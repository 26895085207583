import m             from "mithril";
import { interpret } from "@xstate/fsm";
import cssjoiner     from "cssjoiner";

import schemas from "../../../../preload/schemas";

import createCommPrefsMachine from "./comm-prefs-machine";

import globalState from "../../../../state";

import css from "../../index.css";

const i18n = globalState.i18n.settings.commPrefs;

const lists = [
    [ "anet", i18n.anet ],
    [ "gw2", "Guild Wars 2" ],
    [ "gw", "Guild Wars" ]
];

export default {    // component gets recreated on save, so machine is reinitialized
    oninit({ state }) {
        const machine = createCommPrefsMachine(globalState);

        state.commPrefsService = interpret(machine);
        state.commPrefsService.subscribe(cps => {
            state.commPrefs = cps.context;
        });
        state.commPrefsService.start();
    },

    onremove({ state }) {
        // this unsubscribes all subscriptions
        // https://xstate.js.org/docs/packages/xstate-fsm/#service-stop
        state.commPrefsService.stop();
    },

    view({ state, attrs }) {
        const noSubsChecked = Object.values(state.commPrefs.curr.subscriptions).every(value => !value);
        const saveDisabled  = state.commPrefs.saved || !state.valid;

        return [
            m("div", { class : css.subscriptions },
                lists.map(([ key, label ]) =>
                    m("div", { class : css.modField },
                        m("label", { for : key },
                            label,

                            m("input", {
                                class   : css.checkbox,
                                type    : "checkbox",
                                id      : key,
                                name    : key,
                                checked : state.commPrefs.curr.subscriptions[key],

                                "data-checkbox2" : true,
                                "data-test"      : `commPrefs-${key}`,

                                onchange() {
                                    state.commPrefsService.send({ type : "TOGGLESUB", subscription : key });
                                    state.valid = attrs.isValid(schemas.commPrefs);
                                }
                            })
                        )
                    )
                )
            ),

            m("div.modField",
                i18n.language,
                m("div", { class : css.modSelect },
                    m("select", {
                            name     : "lang",
                            disabled : noSubsChecked,

                            "data-test" : `commPrefs-lang`,

                            onchange(e) {
                                state.commPrefsService.send({ type : "SETLANG", lang : e.target.value });
                                state.valid = attrs.isValid(schemas.commPrefs);
                            }
                        },
                        globalState.langs.map(lang =>
                            m("option", {
                                value    : lang,
                                selected : state.commPrefs.curr.lang === lang
                            }, lang.toUpperCase())
                        )
                    )
                )
            ),

            m("button[type=submit]", {
                    class : cssjoiner(
                        "pure-button",
                        "pure-button-primary",
                        [ saveDisabled, css.saved ]
                    ),
                    disabled    : saveDisabled,
                    "data-test" : "commPrefs-save"
                },
                state.commPrefs.saved ? i18n.prefsSaved : i18n.updatePrefs
            )
        ];
    }
};
