import m from "mithril";

import stepContainer    from "../step-container";
import verificationForm from "../link/verification-form";

import qr from "../../../../components/qr";

import globalState from "../../../../state";

import css from "../index.css";

const i18n = globalState.i18n.twostep.signup.totp.link;

export default {
    view() {
        const secret = globalState.shared_secret;

        return m(stepContainer,
            m("p", { class : css.stepInfo }, i18n.info),

            m("h3", i18n.title),

            m(".pure-u-1.pure-u-md-14-24",
                m("p", { class : css.noMargin }, i18n.body1),

                m("p", { class : css.secretLabel }, i18n.yourCode),

                m("p", { class : css.secretCode }, secret),

                m("p", i18n.body2)
            ),

            m(".pure-u-1.pure-u-md-10-24", { class : css.qrCode },
                secret ?
                    m(qr, { value : `otpauth://totp/GW2-${globalState.user.login_name}?secret=${secret}` }) :
                    null
            ),

            m("h3", { class : css.codeTitle }, i18n["code-title"]),

            m("p", i18n["code-body"]),

            m(verificationForm, { linkType : "totp" })
        );
    }
};
