import m from "mithril";

import a from "../../../../components/anchor";

import globalState from "../../../../state";

import stepContainer from "../step-container";

import css from "../index.css";

export default {
    view({ attrs }) {
        const i18n = globalState.i18n.twostep.unlink[attrs.type].done;

        return m(stepContainer,
            m("h3", i18n.title),

            m("p", i18n.body1),

            m("p", (globalState.user && globalState.user.email_verified) ? i18n.email : i18n.none),

            m("div", { class : css.stepFt },
                m(a, {
                        class : "pure-button pure-button-primary",
                        href  : "/security/settings"
                    },
                    globalState.i18n.twostep.finish
                )
            )
        );
    }
};
