import m from "mithril";

import formField from "../../../../components/form-field";

import globalState from "../../../../state";

import css from "../../index.css";

const formI18n = globalState.i18n.forms.register;

export default {
    view : () => [
        m(formField, {
            name       : "code",
            labelClass : "pure-u-1",
            strings    : formI18n.code
        }),
        m("a[tabindex=-1].pure-u-1", {
                class  : css.help,
                href   : globalState.get("urls.findingCodes"),
                target : "_blank",
                rel    : "noopener noreferrer"
            },
            globalState.i18n.forms.register.code.help
        )
    ]
};
