import m         from "mithril";
import cssjoiner from "cssjoiner";

import formField from "../../components/form-field";

import globalState from "../../state";

const formI18n = globalState.i18n["password-inputs"];

export default {
    oninit({ state }) {
        state.suggestedPassword = null;
        state.register          = m.route.get().includes("register");
        state.password          = "";
    },

    view : ({ state }) => {
        const usingPwSuggestion = state.suggestedPassword === state.password;

        // Register has different placeholder strings and that's the only difference
        if (state.register) {
            formI18n.password.label  = formI18n.register.password.label;
            formI18n.password2.label = formI18n.register.password2.label;
        }

        return m("div",
            // using a suggested password means portal adds that password to the blacklist
            usingPwSuggestion ?
                m("input", {
                    type  : "hidden",
                    name  : "suggested",
                    value : true
                }) :
                null,

            m(formField, {
                name         : "password",
                autocomplete : "new-password",
                strings      : formI18n.password,
                type         : usingPwSuggestion ? "text" : "password",
                value        : state.password,
                labelClass   : cssjoiner(
                    "pure-u-1",
                    [ !state.register, "pure-u-md-17-24" ]
                ),
                oninput : m.withAttr("value", value => {
                        state.password = value;
                    }),
                tooltipExtra : {
                    strings : formI18n.password.suggest,
                    attrs   : {
                        type        : "button",
                        tabindex    : -1,
                        "data-test" : "passwordSuggestion",

                        onmousedown(e) {
                            e.preventDefault();

                            if (state.gettingPassword) {
                                return;
                            }

                            state.gettingPassword = true;

                            m.request({ url : `/ws/password?${m.buildQueryString({ _t : Date.now() })}` })
                                .then(({ suggested_password : suggestedPassword }) => {
                                    state.password = state.suggestedPassword = suggestedPassword;
                                    delete state.gettingPassword;
                                });
                        }
                    }
                }
            }),

            m(formField, {
                name         : "password2",
                autocomplete : "new-password",
                strings      : state.register ? formI18n.register.password2 : formI18n.password2,
                labelClass   : cssjoiner(
                    "pure-u-1",
                    [ !state.register, "pure-u-md-17-24" ]
                ),
                type : "password"
            })
        );
    }
};
