import globalState from "../../state";

import { initRecaptcha } from "../../util/captcha";

import errIcon from "../form-field/errIcon";

import css from "./recaptcha.css";

export default {
    view({ state }) {
        const recaptchaErr = globalState.get("form.errors.recaptcha");

        return m("div[data-test=recaptchaContainer]", { class : css.recaptchaContainer },
            m("div", {
                class : recaptchaErr ? css.recaptchaErr : css.recaptcha,
                id    : "recaptcha",

                oncreate({ dom }) {
                    initRecaptcha({ dom, callback(response) {
                        state.recaptchaResponse = response;
                        m.redraw();
                    } });
                }
            }),
            recaptchaErr ? m(errIcon) : null,

            // for validation
            m("input[type=hidden][name=recaptcha]", { value : state.recaptchaResponse })
        );
    }
};
