import m       from "mithril";
import { sub } from "template";

import formField from "../../../../../components/form-field";

import handleForm from "../../../../../util/handle-form";

import stepContainer from "../../step-container";

import globalState from "../../../../../state";

import css from "../../index.css";

const i18n = globalState.i18n.twostep.unlink.sms.remove;

export default {
    view : () =>
        m(stepContainer,
            m.route.param("resend") && !globalState.errorMessage ?
                m("div", { class : css.resent }, i18n.resent) :
                null,

            m("h3", i18n.title),

            m("p", m.trust(i18n.body1)),

            m("p", sub(i18n.body2, { phone : globalState.phone })),

            m("form", handleForm({
                    method : "POST",
                    action : "/security/2-step/sms/unlink/remove",
                    class  : css.smsRemove
                }),
                m("input[type=hidden][name=_formName][value=sms-remove]"),

                m(formField, {
                    name      : "otp1",
                    strings   : globalState.i18n.forms["sms-remove"].otp1,
                    class     : "pure-u-1",
                    // TODO all options sent in correctly?
                    maxlength : 5
                }),

                m("div", { class : css.stepFt },
                    m("button[type=submit].pure-button.pure-button-primary", { class : css.next },
                        globalState.i18n.twostep.next
                    )
                )
            ),

            m("div", { class : css.smsResend },
                m("form[method=post][action=/security/2-step/sms/unlink/warning]", handleForm(),
                    i18n.resend.text,

                    m("input[type=hidden][name=resend][value=true]"),

                    m("button[type=submit]", { class : css.buttonLink },
                        i18n.resend.link
                    )
                )
            )
        )
};
