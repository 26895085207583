import objectPath from "object-path";

import requestPageState from "./request-page";

// pages to preload
const preloadPages = [
    "overview",
    "settings",
    "security/settings",
    "content",
    "applications"
];

const excludePages = [
    "allowlist-ip",
    "allowlist-ip/success",
    "login/steam/return"
];

// don't need to load these if navigating through app
const excludeRoutes = [
    /applications\/[0-9A-Z]{8}-[0-9A-Z]{4}-[0-9A-Z]{4}-[0-9A-Z]{4}-[0-9A-Z]{12}\/delete/,
    "security/2-step/sms/unlink/warning",
    /applications\/[0-9A-Z]{8}-[0-9A-Z]{4}-[0-9A-Z]{4}-[0-9A-Z]{4}-[0-9A-Z]{12}\/edit/
];

/**
 * Check if route matches any excludes
 * @param {string} page - route to check
 * @param {[string|regexp]} excludes - routes to check against
 * @return {boolean} whether the page matches any routes
 */
function checkExcludes(page, excludes) {
    return excludes.some(test => {
        if (test.length && page === test) {
            return true;
        }

        if (test.test && test.test(page)) {
            return true;
        }

        return false;
    });
}

export default class Loader {
    constructor() {
        // map of loaded pages
        this.loaded = {};

        this.loading = false;

        // keep track of current preloading state
        this._preloading     = false;
        this._preloadPromise = false;
        this._preloadPages   = [];
    }

    /**
     * Finished preloading?
     */
    preloaded() {
        return this._preloadPromise !== false && !this._preloading;
    }

    /**
     * Given _preloadPages (defined in constructor), load all of these pages in and return them to globalState
     */
    preloadPages(currentPage, loggedIn, state, background = true) {
        if (this.preloaded() || !loggedIn) {
            return;
        }

        this._preloading = true;

        this._preloadPages = preloadPages
            // filter out already loaded/loading pages before preload
            .filter(page =>
                !(page in this.loaded) &&
                !(this.loading && page === currentPage) &&
                !(state.isSteamUser && page === "security/settings")
            );

        // preloader shouldn't manage this.loading
        // if (!background && !this.loading) {
        //     this.loading = true;

        //     m.redraw();
        // }

        this._preloadPromise = Promise.all(
            this._preloadPages.map(page =>
                requestPageState(page)
                    .then(res => {
                        this.loaded[page] = true;

                        state.update(res);
                    })
                )
        )
        .then(() => {
            // don't set this.loading to false here
            // getState() might take longer and current page won't have necessary data to render
            this._preloading = false;
        })
        .catch(err => {
            // todo: loaded = true?
            console.log(err);
        });

        return this._preloadPromise;
    }

    /**
     * Make all retrieved data invalidated so we re-lazy load it/preload it
     */
    invalidateLoaded() {
        this._preloadPromise = false;
        this.loaded          = {};
    }

    /**
     * Used to load state in to current route. Every page has a specific state that is loaded directly.
     * @param {string} currentPage - current route
     * @param {object} state - globalState instance
     * @param {object} [opts] - options
     * @param {boolean} opts.first - can't exclude loading data on excludeRoutes if first
     */
    getState(currentPage, state, { first } = {}) {
        // sometimes never need to fetch data, or
        // don't need to fetch when changing page in app
        if (checkExcludes(currentPage, excludePages) || (!first && checkExcludes(currentPage, excludeRoutes))) {
            // everyone upstream is expecting a promise
            return Promise.resolve();
        }

        if (!this.loaded[currentPage]) {
            this.loading = true;

            m.redraw();
        }

        // Don't display loading spinner on current page if we're currently preloading pages unless
        // the user opens a page that is inside the current preload queue
        if (this._preloadPages.includes(currentPage) && this._preloading) {
            this.loading = true;

            // after preload is finished, make sure to turn off the loading spinner
            return this._preloadPromise
                .then(() => {
                    this.loading = false;
                });
        }

        return requestPageState(currentPage)
            .then(resp => {
                if (resp && resp.redirect) {
                    if (resp.redirect.match(/http(?:s)?:/)) {
                        window.location.href = resp.redirect;

                        return;
                    }

                    return m.route.set(resp.redirect);
                }

                if (resp) {
                    state.update(resp);
                }

                this.loading             = false;
                this.loaded[currentPage] = true;
            })
            .catch(err => {
                state.update({ devReason : objectPath.get(err, "error.devReason"), errorMessage : "requestPageState error" });

                this.loading             = false;
                this.loaded[currentPage] = true;

                console.error(err);
            });
    }
}
