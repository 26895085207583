import m from "mithril";

import modalContainer from "../components/modal-container";

import handleForm from "../../../util/handle-form";

import formField from "../../form-field";

import globalState from "../../../state";

const i18n     = globalState.i18n.linkAccounts;
const formI18n = globalState.i18n.forms["link-accounts"];

export default {
    view : () =>
        m(modalContainer,
            /* eslint no-nested-ternary : "off" */
            globalState.hasGw1 ?
                m("h3",
                    m.trust(i18n.title.linked)
                ) :
                globalState.postSuccess ? 
                    [
                        m("h3", m.trust(i18n.success.title)),

                        m("p", m.trust(i18n.success.body)),

                        m("p", globalState.user.login_name)
                    ] : 
                    [
                        m("h3", m.trust(i18n.title.unlinked)),

                        m("p", m.trust(i18n.body1)),

                        m("p", m.trust(i18n.body2)),

                        m("form.pure-form[method=post][action=/modals/link-accounts]",
                            handleForm(),
                            m("input[type=hidden][name=_formName][value=link-accounts]"),

                            m(formField, {
                                name       : "email", 
                                strings    : formI18n.email,   
                                labelClass : "pure-u-1 pure-u-md-17-24", 
                                type       : "email" 
                            }),

                            m(formField, {
                                name       : "password",
                                strings    : formI18n.password,
                                labelClass : "pure-u-1 pure-u-md-17-24", 
                                type       : "password" 
                            }),
                            
                            // TODO : password reset link
                            m("button[type=submit].pure-button.pure-button-primary", globalState.i18n.button)
                        )
                    ]
        )
};
