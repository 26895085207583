import a         from "../../../../components/anchor";
import formField from "../../../../components/form-field";

import handleForm from "../../../../util/handle-form";

import header from "../header";

import css from "../../index.css";

export default {
    oninit(vnode) {
        vnode.state.value = vnode.attrs.value;
    },

    view : vnode =>
        m(`div`, {
                class       : css.applications,
                "data-test" : `${vnode.attrs.name}Form`
            },
            m(".pure-u-1",
                m(header, { header : vnode.attrs.i18n.header, desc : vnode.attrs.i18n.desc }),

                m(".module.pure-u-1", { class : css.module },
                    m("form.pure-form", handleForm({
                            strings : vnode.attrs.formI18n.name,
                            method  : "post",
                            action  : vnode.attrs.action
                        }),

                        m("input[type='hidden'][name='_formName'][value='apikey']"),

                        m(formField, {
                            name       : "name",
                            strings    : vnode.attrs.formI18n.name,
                            labelClass : "pure-u-1",
                            maxlength  : 128,
                            value      : vnode.state.value,
                            oninput    : m.withAttr("value", v => {
                                vnode.state.value = v;
                            })
                        }),

                        vnode.children,

                        m("button.pure-button.pure-button-primary[type=submit]",
                            vnode.attrs.i18n.submit
                        ),

                        m(a, {
                            href  : "/applications",
                            class : [ "pure-button", css.pureBtnSecondary ].join(" ")
                        }, vnode.attrs.i18n.cancel)
                    )
                )
            )
        )
};
