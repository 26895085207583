"use strict";

import Sketch   from "sketch-js";
import Particle from "./particle";

const regionData = {
        maxWidth  : 1000,
        maxHeight : 920,

        width  : 30,
        height : 100
    };

const MAX_POOL     = 1000;
const particleRate = 100; // pps

let emitDelta = 0, // last time a particle was emitted (important if emitting less than 1 particle per frame)
    particles = []; // particle pool

function addParticles(ctx, pta) {
    const x       = (ctx.width - regionData.maxWidth) / 2;
    const regions = [{
        x1 : x,
        y1 : regionData.maxHeight,
        x2 : x + 1500,
        y2 : regionData.maxHeight + regionData.height
    }];

    for (let i = 0; i < pta; i++) {
        particles.push(new Particle({ regions }));
    }
}

export default function(container) {
    return Sketch.create({
        // todo: prob need to reduce scope here
        autostart  : true,
        fullscreen : false,
        width      : container.offsetWidth,
        height     : container.offsetHeight,
        container,

        update() {
            emitDelta = emitDelta + this.dt;

            const particlesToAdd = Math.round(particleRate / 1000 * emitDelta);

            // update other particles, returns false if particle isDead()
            particles = particles.filter(p => p.update(this));

            if (!particlesToAdd) {
                return;
            }

            emitDelta = 0;

            addParticles(this, particlesToAdd);
        },

        draw() {
            // ensure canvas spans full width if window resizes
            if (this.width !== container.offsetWidth) {
                this.width = container.offsetWidth;
            }

            this.globalCompositeOperation = "lighter";

            particles.forEach(p => {
                p.draw(this);
            });
        }
    });
}
