import mailcheck from "mailcheck";

// Additional common domains from our data.
mailcheck.defaultDomains.push(
    "163.com",
    "abv.bg",
    "adelphia.net",
    "aim.com",
    "alice.it",
    "aol.com",
    "arcor.de",
    "att.net",
    "bellsouth.net",
    "bigpond.com",
    "bluewin.ch",
    "blueyonder.co.uk",
    "btinternet.com",
    "charter.net",
    "club-internet.fr",
    "comcast.net",
    "cox.net",
    "earthlink.net",
    "excite.com",
    "free.fr",
    "freemail.hu",
    "freenet.de",
    "gmail.com",
    "gmx.at",
    "gmx.ch",
    "gmx.de",
    "gmx.net",
    "googlemail.com",
    "hotmail.ca",
    "hotmail.co.uk",
    "hotmail.com",
    "hotmail.de",
    "hotmail.es",
    "hotmail.fr",
    "hotmail.it",
    "interia.pl",
    "juno.com",
    "laposte.net",
    "libero.it",
    "live.ca",
    "live.co.uk",
    "live.com",
    "live.com.au",
    "live.de",
    "live.fr",
    "live.nl",
    "live.se",
    "mail.com",
    "mail.ru",
    "me.com",
    "msn.com",
    "naver.com",
    "netscape.net",
    "neuf.fr",
    "ntlworld.com",
    "o2.pl",
    "online.de",
    "op.pl",
    "optonline.net",
    "orange.fr",
    "outlook.com",
    "qq.com",
    "rambler.ru",
    "rocketmail.com",
    "rogers.com",
    "sbcglobal.net",
    "seznam.cz",
    "shaw.ca",
    "skynet.be",
    "sympatico.ca",
    "t-online.de",
    "telenet.be",
    "telus.net",
    "tiscali.co.uk",
    "tlen.pl",
    "us.army.mil",
    "verizon.net",
    "videotron.ca",
    "voila.fr",
    "vp.pl",
    "wanadoo.fr",
    "web.de",
    "wp.pl",
    "yahoo.ca",
    "yahoo.cn",
    "yahoo.co.uk",
    "yahoo.com",
    "yahoo.com.au",
    "yahoo.com.cn",
    "yahoo.com.hk",
    "yahoo.com.tw",
    "yahoo.de",
    "yahoo.es",
    "yahoo.fr",
    "yahoo.it",
    "yandex.ru",
    "ymail.com"
);
