import { sub }   from "template";
import mailcheck from "mailcheck";

import * as hideable  from "../../components/hideable";
import formField      from "../../components/form-field";
import a              from "../../components/anchor";
import formLogo       from "../../components/form-logo";
import recaptcha      from "../../components/recaptcha";
import loadingSpinner from "../../components/loading-spinner";


import handleForm          from "../../util/handle-form";
import ga                  from "../../util/ga";
import { CheckEmail }      from "../../util/captcha";
import { debouncePromise } from "../../util/debounce";
import { postForm }        from "../../util/post-form";

import globalState from "../../state";

import css from "./index.css";

import passthroughParams from "./wait/components/passthrough-params";
import thirdPartyLogin   from "./thirdPartyLogin";

const formI18n  = globalState.i18n.forms.login;
const loginI18n = globalState.i18n.login;

export default {
    oninit({ state }) {
        state.emailSuggestion = "";

        state.emailState    = {
            checked : false
        };
        state.fields        = {};
        state.testRecaptcha = m.route.param("testRecaptcha");

        // can't m.route.set() to external links
        if (globalState.redirect && /^http(s):\/\//.test(globalState.redirect)) {
            document.location = globalState.redirect;
        }

        // zendesk JWT login must POST not redirect
        if (globalState.redirect.method === "POST") {
            postForm(globalState.redirect);

            state.posting = true;

            return;
        }

        if (globalState.loggedIn || globalState.redirect) {
            m.route.set(globalState.redirect || "/overview");
        }

        state.checkEmail          = new CheckEmail({ fields : state.fields, type : "login" });
        state.debouncedCheckEmail = debouncePromise(500, state.checkEmail.check.bind(state.checkEmail));
        state.checkEmailCb        = res => {
            Object.assign(state.emailState, res); // this adds `recaptcha : true`
            state.emailState.checked = true;
        };

        // lets display the captcha on load if it is enabled for everyone
        if(globalState.features.captcha && globalState.captcha.loginAttempts === 0){
            state.emailState.recaptcha = true;
        }
    },

    view({ state }) {
        const email = m.route.param("email");

        // can't golf further without binding globalState
        const thirdParties = [ "steam", "epic" ].filter(platform => globalState.get(`features.${platform}`));

        return m("div", {
                    class       : css.loginModule,
                    "data-test" : "loginContainer"
                },

            state.posting ?
                loadingSpinner :
                m("form",
                    handleForm({
                        method : "POST",
                        action : `/login${state.testRecaptcha ? "?testRecaptcha" : ""}`,
                        class  : css.loginForm
                    }),

                    // value of this input is used for determining the schema
                    m("input", {
                        type  : "hidden",
                        name  : "_formName",
                        value : state.emailState.recaptcha ? "login_captcha" : "login"
                    }),


                    // gamecode, redirect_uri, fakeip
                    m(passthroughParams),

                    m("h1.serif",
                        m(formLogo),
                        loginI18n.header
                    ),

                    m(formField, {
                        name         : "email",
                        autocomplete : "username",
                        strings      : formI18n.email,
                        labelClass   : "pure-u-1",
                        autofocus    : true, // for when JS isn't enabled
                        onchange     : m.withAttr("value", value => {
                            const suggestion = mailcheck.run({ email : value });

                            state.emailSuggestion = suggestion ? suggestion.full : "";
                        }),
                        oninput(e) {
                            state.fields.email = e.target.value;
                            state.emailChecked = false;
                            state.debouncedCheckEmail(state.checkEmailCb);
                        },
                        onblur() {
                            state.checkEmail.check()
                                .then(state.checkEmailCb)
                                .catch(() => {}); // eslint-disable-line no-empty-function
                        }
                    }),

                    m("div",  { class : css.loginEmailSuggestion }, state.emailSuggestion ?
                        m.trust(sub(formI18n.emailSuggestion, { email : state.emailSuggestion })) :
                        null
                    ),

                    m(hideable.input, {
                        name         : "password",
                        autocomplete : "current-password",
                        type         : "password",
                        strings      : formI18n.password,
                        labelClass   : "pure-u-1"
                    }),

                    state.emailState.recaptcha ?
                        m(recaptcha) :
                        null,

                    m("button", {
                            class    : css.submit,
                            type     : "submit",

                            onclick() {
                                ga({
                                    event         : "gtm-login",
                                    eventCategory : "login",
                                    eventAction   : "login-click",
                                    eventLabel    : "login"
                                });
                            }
                        },
                        loginI18n.signin
                    )
                ),

                m("p", { class : css.orThirdParty }, m("span", loginI18n.orThirdParty)),

                m("div", { class : css.thirdParties, "data-third-party-count" : thirdParties.length },
                    thirdParties.map(platform => m(thirdPartyLogin, { platform }))
                ),

                m("hr", { class : css.hr }),

                m("div", { class : css.lowerLinks },

                    m(a, {
                        href        : "/register?alt=gw2",
                        "data-test" : "register-link"
                    }, loginI18n.register.linktext),


                    m("span", { class : css.helpLinks },
                        globalState.get("features.passwordReset") ?
                            m(a, {
                                    href        : `/password-reset${email ? `?email=${email}` : ""}`,
                                    class       : css.forgot,
                                    "data-test" : "forgot-password"
                                }, loginI18n.forgot.pwReset) :
                            null,

                        m("a", {
                                href        : globalState.get("urls.troubleLoggingIn"),
                                "data-test" : "trouble-logging-in"
                            }, loginI18n.forgot.trouble)
                    )
                )
            );
    }
};
