import m from "mithril";

import pii               from "../../../components/modals/pii";
import changeDisplayName from "../../../components/modals/change-display-name";

import globalState from "../../../state";

import css from "../index.css";

const i18n = globalState.i18n.settings;

export default {
    view() {
        const displayNameChangeLocked = globalState.appData.locks.displayname;
        const decodedRealName         = decodeURIComponent(globalState.appData.realname);
        const decodedPhone            = decodeURIComponent(globalState.appData.phone);

        return m("div.pure-u-1.pure-u-sm-13-24", { class : css.settingsModule },
            m("h3", i18n.info),

            m("div.modField",
                i18n.email,
                m(".modVal", globalState.user.login_name),
            ),

            globalState.remoteRights !== "None" ?
                m("div.modField",
                    "User ID",
                    m("div[data-test=portal-guid].modVal", globalState.user.user_id)
                ) :
                null,

            // #region Display Name
            m("div.modField",
                i18n.displayname,
                !displayNameChangeLocked ?
                    m("button", {
                        class       : "modLink",
                        "data-test" : "change-displayname",
                        onclick(e) {
                            e.preventDefault();
                            globalState.modal.display(changeDisplayName);
                        }
                    }, globalState.i18n.edit) :
                    null,

                m("div[data-test=displayname].modVal", decodeURIComponent(globalState.user.user_name))
            ),
            // #endregion

            // #region Real Name
            m("div.modField",
                i18n.realname,

                m("button", {
                    class       : "modLink",
                    "data-test" : "change-realname",
                    onclick(e) {
                        e.preventDefault();
                        globalState.modal.display(pii, {
                            name  : "change-realname",
                            field : "realname",
                            test  : o => o._value.substr(0, 100).trim() !== decodedRealName
                        });
                    }
                }, globalState.i18n.edit),

                m("div[data-test=realname].modVal", decodedRealName)
            ),
            // #endregion

            // #region Phone
            m("div.modField",
                i18n.phone,

                m("button", {
                    class       : "modLink",
                    "data-test" : "change-phone",
                    onclick(e) {
                        e.preventDefault();
                        globalState.modal.display(pii, {
                            name  : "change-phone",
                            field : "phone",
                            test  : o => o._value.replace(/\D/g, "") !== decodedPhone
                        });
                    }
                }, globalState.i18n.edit),

                m("div[data-test=phone].modVal", decodedPhone)
            )
            // #endregion
        );
    }
};
