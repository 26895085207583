import m from "mithril";

import css       from "./index.css";
import formField from "../../components/form-field";

const showHide = {
    view({ attrs }) {
        const { state : _state, toggleTitle = "Show/Hide Password" } = attrs;

        return m("button", {
            title : toggleTitle,
            type  : "button",
            class : _state.show ? css.eye : css.eyeSlash,
            onclick(e) {
                _state.show = !_state.show;
                e.preventDefault();
                e.stopPropagation();
            }
        });
    }
};

// formField should use hideable instead
export const input = {
    oninit({ state }) {
        state.show = false;
    },
    view : ({ attrs, state }) => {
        const { required, labelClass, name, strings, toggleTitle = "Show/Hide Password" } = attrs;

        return m(formField, {
                name,
                labelClass,
                required,
                type : state.show ? "text" : "password",
                strings
            },
            m(showHide, { toggleTitle, state })
        );
    }
};

export const text = {
    oninit({ state }) {
        state.show = false;
    },
    view : ({ attrs, state }) => {
        const { value = "", toggleTitle = "Show/Hide Password" } = attrs;

        return m("span", { class : css.text },
            m("span", { class : state.show ? css.textText : css.textPassword, "data-test-unmasked" : value },
                state.show ? value : value.replace(/./g, "*")
            ),

            m(showHide, { toggleTitle, state })
        );
    }

};
