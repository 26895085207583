export function debouncePromise(time, fn) {
    let timeoutId;

    return (...args) => {
        clearTimeout(timeoutId);

        timeoutId = setTimeout(() => {
            let cb = args[0];

            if (args.length > 1) {
                // remove last item from args, and use as callback
                cb = args.splice(args.length - 1, 1);
            }

            fn(...args)
                .then(cb)
                .catch(() => { /* ignored path */ });
        }, time);
    };
}

export function debounce(time, fn) {
    let timeoutId;

    return (...args) => {
        clearTimeout(timeoutId);

        timeoutId = setTimeout(() => {
            fn(...args);
        }, time);
    };
}
