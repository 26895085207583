import m from "mithril";

import globalState from "../state";

const pages = [
    "register",
    "unsubscribe",
    "subscribed",
    "welcome"
];

export default function hide() {
    const route = m.route.get().split("?")[0]; // query param will have "register" after clicking continue on registration

    return !globalState.loggedIn || pages.some(page => route.includes(page));
}
