import m from "mithril";

import a from "../../../../../components/anchor";

import stepContainer from "../../step-container";

import globalState from "../../../../../state";

import css from "../../index.css";

const i18n = globalState.i18n.twostep.unlink.totp.warning;

export default {
    view : () =>
        m(stepContainer,
            m("h3", i18n.title),

            m("p", i18n.body1),

            m("div", { class : css.yesNo },
                m(a, {
                        class : "pure-button pure-button-primary",
                        href  : "/security/2-step/totp/unlink/remove"
                    },
                    i18n.button
                ),

                m(a, {
                        class : css.cancel,
                        href  : "/security/settings"
                    },
                    globalState.i18n.forms.confirm.cancel
                )
            )
        )
};
