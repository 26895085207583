import m from "mithril";

import formField from "../../../../components/form-field";
import a         from "../../../../components/anchor";

import preserveParams from "../../../../util/preserve-params";
import handleForm     from "../../../../util/handle-form";

import stepContainer from "../step-container";

import globalState from "../../../../state";

import css from "../index.css";

const i18n = globalState.i18n.twostep.signup.sms.verify;

export default {
    view() {
        const register  = m.route.get().includes("register");
        const call      = m.route.param("type") === "call";
        const dragonSrc = globalState.theme === "darkmode" ? "https://account.staticwars.com/img/dragon-dm.jpg" : "https://account.staticwars.com/img/dragon.jpg";

        return m(stepContainer, {
                stepCss : globalState.needsMini ? css.needsMini : ""
            },
            globalState.needsMini ?
                m("div", { class : css.dragonArt }) :
                null,

            m("p", { class : css.stepInfo }, i18n.info),

            m("h3",
                m(".pure-u-1.pure-u-md-15-24", i18n.title)
            ),

            m("p",
                globalState.needsMini ?
                    i18n.bodyMini :
                    i18n.body
            ),

            m("form.pure-u-1.pure-u-md-15-24.pure-form[method=post]", handleForm({
                    action : preserveParams("/security/2-step/sms/verify", "alt")
                }),
                m("input[type=hidden][name=_formName][value=sms-verify]"),

                m("select[name=countryCode]",
                    globalState.countries.map(country =>
                        m("option", {
                                value    : country.dialingCode,
                                selected : country.countryCode === globalState.myCountry
                            },
                            `${decodeURIComponent(country.name)} (+${country.dialingCode})`
                        )
                    )
                ),

                m(formField, {
                    name       : "phone",
                    labelClass : "pure-u-1 pure-u-md-21-24",
                    strings    : globalState.i18n.forms["sms-verify"].phone,

                    autocomplete : "tel"
                }),

                m("div",
                    m(formField, {
                        name       : "type",
                        labelClass : "pure-u",
                        strings    : globalState.i18n.forms["sms-verify"].type,
                        type       : "radio",
                        checked    : !call,
                        value      : "text",
                        disabled   : "disabled",
                        label      : i18n.text
                    })
                ),

                m("button[type=submit].pure-button.pure-button-primary",
                    i18n.next
                )
            ),

            globalState.needsMini ?
                m(".pure-u-1.pure-u-md-9-24", { class : css.miniDragon },
                    m("img", { src : dragonSrc, alt : "" }),

                    m("div", i18n.dragon)
                ) :
                null,

            m("div",
                m(".pure-u", { class : css.noSms },
                    `${i18n.nosms.text} `,
                    m(a, {
                            href : preserveParams("/security/2-step/totp/download", "alt")
                        },
                        i18n.nosms.link
                    )
                ),

                register ?
                    m(a, {
                            class : css.cancel,
                            href  : preserveParams("/security/2-step/later", "alt")
                        },
                        i18n.later
                    ) :
                    null,

                m("p", { class : css.disclaimer }, i18n.disclaimer)
            )
        );
    }
};
