import m from "mithril";

import anetfooter from "anetfooter";

import globalState from "../../../../state";

export default {
    view : () =>
        m(anetfooter, {
            lang     : globalState.lang,
            rating   : globalState.rating,
            noSocial : true,
            curDate  : new Date().getFullYear()
        })
};
