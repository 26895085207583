import m from "mithril";

import a from "../../../components/anchor";

import preserveParams from "../../../util/preserve-params";

import stepContainer from "./step-container";

import globalState from "../../../state";

import css from "./index.css";

const i18n = globalState.i18n.twostep.signup.later;

export default {
    view : () =>
        m(stepContainer,
            m("p", { class : css.stepInfo }, i18n.info),

            m("h3", i18n.title),

            m("p", i18n.body1),

            m("div", { class : css.stepFt },
                m(a, {
                        class : "pure-button pure-button-primary",
                        href  : m.route.get().includes("register") ? preserveParams("/welcome", "alt") : "/content"
                    },
                    globalState.i18n.twostep.finish
                )
            )
        )
};
