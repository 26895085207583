import m from "mithril";

import addCodeModal from "../../../components/modals/add-code";

import globalState from "../../../state";

import css from "../index.css";

export const addCodeBtn = {
    view() {
        const gcI18n = globalState.i18n.gameContent;

        // top heading and Add A Code
        return m("div.pure-u-1.pure-u-md-19-24", { class : css.contentHd },
            m("button.modLink.floatRight", {
                    class : css.addCode,
                    onclick() {
                        globalState.modal.display(addCodeModal);
                    }
                },
                gcI18n.openCode
            )
        );
    }
};

export const addCodePanel = {
    view() {
        const gcI18n = globalState.i18n.gameContent;

        return m("div.module.pure-u-1.pure-u-md-19-24", { class : css.module },
            m("button", {
                    class : css.openCode,
                    onclick(e) {
                        e.preventDefault();
                        globalState.modal.display(addCodeModal);
                    }
                },

                m("h3", { class : css.gcHeading },
                    gcI18n.openCode
                )
            )

        );
    }
};
