import m from "mithril";

import a from "../../../../components/anchor";

import preserveParams from "../../../../util/preserve-params";

import stepContainer from "../step-container";

import globalState from "../../../../state";

import css from "../index.css";

const i18n = globalState.i18n.twostep.signup.totp.download;

export default {
    view() {
        const fromRegister = m.route.get().includes("register");

        return m(stepContainer,
            m("p", { class : css.stepInfo }, i18n.info),

            m("h3", i18n.title),

            m("p", i18n.body1),

            m(".pure-u-md-2-3.pure-u-1",
                m("p", i18n.mobile),

                m("ul",
                    m("li.pure-u", { class : css.totpDl },
                        m("a[href=http://itunes.apple.com/us/app/google-authenticator/id388497605?mt=8&uo=4][target=ios]",
                            m("img", {
                                alt    : "Google Authenticator - Google, Inc.",
                                src    : "https://account.staticwars.com/pages/security/2-step/totp/img/ios.jpg",
                                width  : "122",
                                height : "40"
                            })
                        )
                    ),

                    m("li.pure-u", { class : css.totpDl },
                        m("a[href=http://play.google.com/store/apps/details?id=com.google.android.apps.authenticator2][target=android]",
                            m("img", {
                                alt    : "Android app on Google Play",
                                src    : "https://account.staticwars.com/pages/security/2-step/totp/img/android.jpg",
                                width  : "125",
                                height : "40"
                            })
                        )
                    ),

                    m("li.pure-u", { class : css.totpDl },
                        m("a[href=http://windowsphone.com/s?appId=e7994dbc-2336-4950-91ba-ca22d653759b][target=winpho]",
                            m("img", {
                                alt    : "Windows Phone Authenticator",
                                src    : "https://account.staticwars.com/pages/security/2-step/totp/img/wp-button.png",
                                width  : "125",
                                height : "40"
                            })
                        )
                    )
                )
            ),

            m(".pure-u-md-1-3.pure-u-1",
                m("p", i18n.desktop),
                m("ul",
                    m("li.pure-u-1", { class : css.totpDl },
                        m("a", {
                                href   : "https://winauth.github.io/winauth/",
                                target : "_blank",
                                rel    : "noopener noreferrer",
                                class  : css.winAuth
                            }, i18n.winAuth
                        )
                    )
                )
            ),
            m("div", { class : css.stepFt },
                fromRegister ?
                    m(a, {
                            href  : preserveParams("/security/2-step/later", "alt"),
                            class : css.cancel
                        },
                        globalState.i18n.twostep.registered.cancel
                    ) :
                    null,

                m(a, {
                        class : `${css.next} pure-button pure-button-primary`,
                        href  : preserveParams("/security/2-step/totp/link", "alt")
                    },
                    globalState.i18n.twostep.next
                )
            )
        );
    }
};
