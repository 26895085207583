import { createMachine, assign } from "@xstate/fsm";

export default function createCommPrefsMachine({ commPrefs, langs }) {
    const { subscriptions, lang } = commPrefs;

    return createMachine({
        id      : "commPrefs",
        initial : "idle",

        context : {
            saved : true,
            langs,

            init : {
                subscriptions,
                lang
            },

            curr : {
                // can't be the same ref as init.subscriptions
                subscriptions : Object.assign({}, subscriptions),
                lang
            }
        },

        states : {
            idle : {
                on : {
                    TOGGLESUB : {
                        target  : "idle",
                        actions : [ "toggle", "checkSaved" ]
                    },
                    SETLANG : {
                        target  : "idle",
                        actions : [ "lang", "checkSaved" ]
                    },
                    SUBMIT : "loading"
                }
            },
            loading : {
                // component and therefore state machine get recreated on save
                // type : "final" // xstate/fsm doesn't support final
            }
        }
    }, {
        actions : {
            toggle : assign((context, event) => {
                if (!(event.subscription in context.curr.subscriptions)) {
                    return;
                }

                context.curr.subscriptions[event.subscription] = !context.curr.subscriptions[event.subscription];

                return context;
            }),
            lang : assign((context, event) => {
                if (!context.langs.includes(event.lang)) {
                    return;
                }

                context.curr.lang = event.lang;

                return context;
            }),
            checkSaved : assign({
                saved : context =>
                    JSON.stringify(context.init) === JSON.stringify(context.curr)
            })
        }
    });
}
