import m from "mithril";

import ga from "../../../util/ga";

import globalState from "../../../state";

import css from "../index.css";

const pageI18n    = globalState.i18n.overview;
const upgradeType = {
    pof : {
        css   : css.upgradePof,
        label : "Pof"
    },
    eod : {
        css   : css.upgradeEod,
        label : "Eod"
    },
    soto : {
        css   : css.upgradeSoto,
        label : "Soto"
    },
    jw : {
        css   : css.upgradeJw,
        label : "Jw"
    }
};

export default {
    view() {
        const missingExpansion = globalState.missingExpansions.find(key => key !== "hot");

        if (globalState.loading || !missingExpansion) {
            return null;
        }

        const upgrade = upgradeType[missingExpansion];

        return m("div.pure-u-1.pure-u-md-1.pure-u-sm-20-24", { class : css.module },
                m("a", {
                        href  : globalState.getBuyLink(),
                        class : upgrade.css,
                        onclick() {
                            ga({
                                event         : "gtm-upgrade",
                                eventCategory : "upgrade",
                                eventAction   : "upgrade-click",
                                eventLabel    : `upgrade-sidebar${upgrade.label}`
                            });
                        }
                    },
                    pageI18n.upsell
                )
            );
    }
};
