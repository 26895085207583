import m                from "mithril";
import animationResolve from "animation-resolve";
import { sub }          from "template";

import globalState from "../../state";

import css from "./index.css";

import detectOs        from "../../util/detect-os";
import getDownloadLink from "../../util/get-download-link";
import gamesight       from "../../util/gamesight";

import fire from "./fire";

const wI18n    = globalState.i18n.welcome;
const eulaI18n = globalState.i18n.eulaAck;

export default {
    title       : globalState.i18n.header.download.title,
    description : globalState.i18n.header.download.description,

    oninit({ state }) {
        const os = detectOs();

        const client = globalState.gameList
            .find(c => c.os === os.type && c.bits === os.bits);

        state.client = client || globalState.gameList[0];
    },

    view({ state }) {
        return [
            m("div", { class : css.module },
                m("img", {
                    class : css.logo,
                    src   : "https://account.staticwars.com/img/gw2-logo.png",
                    alt   : ""
                }),

                m("h3", { class : css.intro }, wI18n.heading2),

                m("div",
                    m("a", {
                            key         : state.client.os,
                            class       : css.downloadBtnIn,
                            "data-test" : "downloadClient",
                            href        : getDownloadLink(state.client),
                            onclick() {
                                gamesight("send", "account_anet_download_installer");
                            },
                            onbeforeremove({ dom }) {
                                return animationResolve(dom, css.downloadBtnOut);
                            }
                        },
                        wI18n.downloadButton[state.client.os]
                    )
                ),

                // EULA Ack
                m("div", { class : css.eulaAck },
                    m.trust(sub(eulaI18n, { downloadText : wI18n.downloadButton[state.client.os] }))
                )
            ),

            m("a[data-test=gettingStarted]", {
                    class : css.gettingStarted,
                    href  : globalState.get("urls.manual")
                },
                m("img", { src : "https://account.staticwars.com/img/gettingStartedBook.png", alt : "" }),

                m("h3", wI18n.info.title),

                m("p", wI18n.info.box1)
            ),

            m(fire)
        ];
    }
};
