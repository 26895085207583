import globalState from "../../state";

import css from "./index.css";

// singleton state to cache img data
const cache = {};

export default {
    oninit({ attrs }) {
        if (cache[attrs.value]) {
            return;
        }

        // POST as the data is likely sensitive
        // would have been nice to just be able to m("img", { src : "/ws/qr?data=xxx" })
        m.request({
            method : "POST",
            url    : "/ws/qr",
            data   : {
                data : attrs.value
            }
        }).then(resp => {
            cache[attrs.value] = resp.base64;
        });
    },
    view({ attrs }) {
        return m("img", {
            class  : cache[attrs.value] ? css.show : css.hide,
            src    : cache[attrs.value],
            width  : attrs.size || 200,
            height : attrs.size || 200,
            alt    : globalState.i18n.qrcode
        });
    }
};
