import m from "mithril";

import page404 from "../../404";

import checkError    from "./components/check-error";
import waitEmailLink from "./components/wait-email-link";
import waitAllowlist from "./components/wait-allowlist";
import waitForm      from "./components/form";
import verifyEmail   from "./components/verify-email";

const subviews = {
    email : {
        view : () => m(checkError, m(waitForm, { type : "email" }))
    },

    "email-link" : {
        view : ({ attrs }) => m(waitEmailLink, attrs)
    },

    totp : {
        view : () => m(checkError, m(waitForm, { type : "totp" }))
    },

    allowlist : {
        view : () => m(waitAllowlist)
    },

    sms : {
        view : () => m(checkError, m(waitForm, { type : "sms" }))
    },

    "verify-email" : {
        view : ({ attrs }) => m(verifyEmail, attrs)
    }
};

export default {
    oninit({ state }) {
        state.failed = false;

        const query = m.buildQueryString({
            game_code    : m.route.param("game_code"),
            redirect_uri : m.route.param("redirect_uri")
        });

        state.pollEmail = () =>
            m.request({
                method     : "POST",
                url        : `/ws/wait.json?${query}`,
                background : true
            })
            .then(res => {
                if (!res.redirect) {
                    return state.pollEmail();
                }

                m.route.set(res.redirect);
            })
            .catch(() => {
                state.failed = true;
            });

        // Special-case for polling stuff.
        if (m.route.param("auth_type") === "email-link") {
            state.pollEmail();
        }
    },

    view() {
        const type = m.route.param("auth_type");

        if (!subviews[type]) {
            return m(page404);
        }

        return m(subviews[type]);
    }
};
