import m from "mithril";

import handleForm from "../../../../../util/handle-form";

import stepContainer from "../../step-container";

import globalState from "../../../../../state";

import a from "../../../../../components/anchor";

import css from "../../index.css";

const i18n = globalState.i18n.twostep.unlink.sms.warning;

export default {
    view : () =>
        m(stepContainer,
            m("h3", i18n.title),

            m("p", i18n.body1),

            m("div", { class : css.stepFt },
                m("form[method=post][action=/security/2-step/sms/unlink/warning]", handleForm(),
                    m("button.pure-button.pure-button-primary[type=submit]", { class : css.next },
                        i18n.button
                    )
                ),

                m(a, {
                        class : css.cancel,
                        href  : "/security/settings"
                    },
                    globalState.i18n.forms.confirm.cancel
                )
            )
        )
};
