"use strict";

const epicRegex = /^\w+@epic$/;

/**
 * @param {string} login - Account login (email or {epicid}@epic)
 * @returns {boolean} true if Epic account
 */
module.exports = function isEpic(login = "") {
    return epicRegex.test(login);
};
