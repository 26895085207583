import m from "mithril";

import successModal from "../../../../../components/modals/success";

import globalState from "../../../../../state";

import css from "../../index.css";

const dropsCheckI18n = globalState.i18n.drops.check;

const RESPONSE_STATUS = {
    FULFILLED : "fulfilled",
    ERROR     : "error",
    FLOODING  : "flooding",
    NONE      : "none"
};

/**
 * Get strings for twitch check drops modal
 * @param {string} response
 * @returns {{ i18n: { header: string, body: string }, name: response }}
 */
function getModalAttrs(response) {
    let header;

    switch (response) {
        case RESPONSE_STATUS.FULFILLED:
            header = globalState.i18n.success;

            break;
        case RESPONSE_STATUS.NONE:
            header = globalState.i18n.complete;

            break;
        default:
            header = globalState.i18n.failure;

            break;
    }

    return {
        i18n : {
            header,
            body : dropsCheckI18n[response]
        },
        name : response
    };
}

export default {
    checking : false,

    view(vnode) {
        if (!globalState.twitch_id || !globalState.features.checkTwitchDrops) {
            return null;
        }

        return m("button", {
                class       : css.check,
                "data-test" : "check-undelivered-drops",
                onclick(e) {
                    e.preventDefault();

                    vnode.state.checking = true;
                    globalState.loading  = true;

                    m.request({
                        method : "POST",
                        url    : "/twitch/check-drops"
                    })
                    .then((res) => {
                        globalState.modal.display(successModal, getModalAttrs(res.status));
                    })
                    .catch((err) => {
                        globalState.modal.display(successModal, getModalAttrs(err.message));
                    })
                    .finally(() => {
                        vnode.state.checking = false;
                        globalState.loading  = false;
                    });
                },
                disabled : vnode.state.checking
            },
            dropsCheckI18n.check
        );
    }
};
