import m       from "mithril";
import { sub } from "template";

import a from "../../../../components/anchor";

import preserveParams from "../../../../util/preserve-params";

import stepContainer from "../step-container";

import globalState from "../../../../state";

import css from "../index.css";

export default {
    view({ attrs }) {
        const fromRegister = m.route.get().includes("register");
        const showMini     = m.route.param("miniDelivered");
        const i18n         = globalState.i18n.twostep.signup[attrs.type].done;
        const dragonSrc    = globalState.theme === "darkmode" ? "https://account.staticwars.com/img/dragon-dm.jpg" : "https://account.staticwars.com/img/dragon.jpg";

        return m(stepContainer,
            m("p", { class : css.stepInfo }, i18n.info),

            m("h3", i18n.title),

            m(".pure-u-1", { class : showMini ? "pure-u-md-10-24" : null },
                m("p", sub(i18n.body1, { email : globalState.user.login_name })),

                m("p", i18n.body2)
            ),

            showMini ?
                m(".pure-u-1.pure-u-md-14-24", { class : css.miniDragon },
                    m(".pure-u-1", { class : css.miniBox },
                        m("img", { src : dragonSrc, alt : "" }),

                        m("div", { class : css.miniTxt }, globalState.i18n.twostep.signup.sms.done.mini)
                    )
                ) :
                null,

            m("div", { class : css.stepFt },
                m(a, {
                        class : "pure-button pure-button-primary",
                        href  : fromRegister ? preserveParams("/welcome", "alt") : "/security/settings"
                    },
                    globalState.i18n.twostep.finish
                )
            )
        );
    }
};
