import m from "mithril";

/**
 * handle linking as a concentrated component
 */
export default {
    view : ({ attrs, children }) =>
        m("a",
            Object.assign(
                { oncreate : m.route.link },
                attrs
            ),
            children
        )
};
