import m from "mithril";

import { sub } from "template";

import form          from "Form";
import getFormValues from "getFormValues";

import globalState from "../../../../state";

import css from "../../index.css";

import handleForm from "../../../../util/handle-form";

import editPrefs from "./edit-prefs";
import signup    from "./signup";

import gift from "./gift";

const i18n = globalState.i18n.settings.commPrefs;

let formElement;

// Check if form is valid
function isValid(schema) {
    if (!formElement) {
        return false;
    }

    const formValues = getFormValues(formElement);

    return form.validate(formValues, schema).valid;
}

const formComponent = {
    view() {
        const isSignup = (globalState.isSteamUser || globalState.isEpicUser) && !globalState.get("commPrefs.email");

        if (globalState.commPrefs.error) {
            return m("p", globalState.i18n.errors.general);
        }

        // after steam user adds their email address they will need to click the link
        // in the double opt-in (doi) email, so tell the user that
        return globalState.commPrefs.doi ?
            m("div",
                m("h4", i18n.header),

                Object.values(i18n.signedUp.body).map(para =>
                    m("p", sub(para, { email : decodeURIComponent(globalState.commPrefs.doiEmail) }))
                )
            ) :
            [
                m(gift),

                isSignup ?
                    m("p", i18n.signUpHeader) :
                    m("h4", i18n.newsletters),

                m(`form.pure-form[method=post][action=/${isSignup ? "comm-prefs-signup" : "comm-prefs"}]`,
                    handleForm({
                        oncreate({ dom }) {
                            // so we can validate
                            formElement = dom;
                        }
                    }),

                    m(`input[type=hidden][name=_formName][value=${isSignup ? "commPrefsSignup" : "commPrefs"}]`),

                    m(`input[type=hidden][name=signupUrl][value=${window.location.href.split("?")[0]}]`),

                    // show input for email address if this is a steam user and we don't have their address
                    isSignup ?
                        m(signup, { isValid }) :
                        m(editPrefs, { isValid })
                )
            ];
    }
};

export default {
    view() {
        return m("div", { class : css.settingsModule },
            m("h3", i18n.header),


            globalState.china ?
                "Disabled on China" :
                [
                    m(formComponent),
                    m("div", { class : css.disc }, m.trust(i18n.disc))
                ]
        );
    }
};
