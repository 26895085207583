import m from "mithril";

import globalState from "../../../state";

const i18n = globalState.i18n.allowlist.add;

export default {
    view : () =>
        m(".module.pure-u-1.pure-u-sm-1-2",
            m("h2", i18n.failure),

            m("p", i18n.result.failure)
        )
};
