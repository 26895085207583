import m from "mithril";

import modalContainer from "../components/modal-container";
import successModal   from "../success";

import confirm from "./confirm";

import handleForm from "../../../util/handle-form";

import formField from "../../form-field";

import schemas from "../../../preload/schemas";

import globalState from "../../../state";

import css from "./index.css";

const { i18n } = globalState;

const once = {};

export default {
    // add new test for uniqueness
    oninit({ attrs }) {
        if (once[attrs.name] || !("test" in attrs)) {
            return;
        }

        once[attrs.name] = true;

        schemas[attrs.name][attrs.field].push(attrs.test);
    },

    view({ attrs }) {
        const { name, field } = attrs;

        if (globalState.postSuccess) {
            return m(successModal, {
                i18n : {
                    header : i18n.success
                }
            });
        }

        return m(modalContainer,
            m("h3", i18n.forms[name].title),

            m(`form.pure-form[method=post][action=/modals/${name}][data-test=${name}-modal]`,
                handleForm(),
                m(`input[type=hidden][name=_formName][value=${name}]`),

                m("div.pure-u-1",
                    m(formField, {
                        name         : field,
                        strings      : i18n.forms[name],
                        labelClass   : "pure-u-1 pure-u-md-17-24",
                        required     : true,
                        autofocus    : true,
                        autocomplete : false
                    })
                ),

                // delete data with confirm
                m("span",
                    m("button[type=submit][data-test=piiSubmit].pure-button.pure-button-primary",
                        i18n.submit
                    ),

                    globalState.appData[field] ?
                        m("button[data-type=secondary][data-test=piiDelete].pure-button",
                            {
                                class : css.deleteBtn,
                                onclick() {
                                    globalState.modal.display(confirm, { name, field });
                                }
                            },
                            i18n.delete
                        ) :
                        null
                ),

                m("div.pure-u", { class : css.info }, i18n.forms.pii.how),
                m("div.pure-u-1",
                    m("p", { class : css.details }, i18n.forms[name].use)
                )
            )
        );
    }
};
