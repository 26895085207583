import m from "mithril";

import formField from "../../../../../components/form-field";

import handleForm from "../../../../../util/handle-form";

import stepContainer from "../../step-container";

import globalState from "../../../../../state";

import css from "../../index.css";

const i18n = globalState.i18n.twostep.unlink.totp.remove;

export default {
    view : () =>
        m(stepContainer,
            m("h3", i18n.title),

            m("p", m.trust(i18n.body1)),

            m("p", m.trust(i18n.body2)),

            m("form.pure-form", handleForm({
                    method : "POST",
                    action : "/security/2-step/totp/unlink/remove"
                }),
                m("input[type=hidden][name=_formName][value=totp-remove]"),

                m(formField, {
                    name       : "otp1",
                    labelClass : "pure-u-1",
                    strings    : globalState.i18n.forms["totp-remove"].otp1,
                    maxlength  : 6
                }),

                m(formField, {
                    name       : "otp2",
                    labelClass : "pure-u-1",
                    strings    : globalState.i18n.forms["totp-remove"].otp2,
                    maxlength  : 6
                }),

                m("div", { class : css.stepFt },
                    m("button[type=submit].pure-button.pure-button-primary", { class : css.next },
                        globalState.i18n.twostep.next
                    )
                )
            )
        )
};
