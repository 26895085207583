import m from "mithril";

import globalState from "../state";

import ga from "./ga";

function createCookie({ key, value, domain, exp }) {
    document.cookie = `${key}=${value};domain=${domain};expires=${exp};SameSite=None;Secure`;
}

/**
 * GW2.com hosts the cid & cidKey cookies, get/set them via an XHR w/ credentials
 * (this is ugly, run and hide)
 */
export default function syncCid(cid) {
    const domain = location.hostname
        .split(".")
        .slice(-2)
        .join(".");
    const cidUrl = globalState.get("urls.cid") || window.state.urls.cid; // global state is prob not kicked off yet

    if (!cidUrl) {
        return;
    }

    const reqParams = {
        _t : Date.now(),
        cid
    };

    // TODO: we could skip this if cid & cidKey cookies already exist?
    m.request({
        url        : `${cidUrl}?${m.buildQueryString(reqParams)}`,
        background : true,
        config(xhr) {
            xhr.withCredentials = true;
        }
    })
    .then(res => {
        // Set cookies for CID script
        if (res.cid) {
            createCookie({ key : "cid", value : res.cid.value, domain, exp : res.cid.date });
        }

        if (res.cidKey) {
            createCookie({ key : "cidKey", value : res.cidKey.value, domain, exp : res.cidKey.date });
        }

        // Tell GTM to load the CID script, we can't trigger this until now
        // because we don't want to change the cidKey if one already exists
        ga({
            event         : "gtm-cidLanding",
            eventCategory : "cid",
            eventAction   : document.location.pathname,
            eventLabel    : cid
        });
    })
    .catch(err => {
        // ! lol
        console.log(err);
        ga({
            event         : "gtm-cidLanding",
            eventCategory : "cid",
            eventAction   : document.location.pathname,
            eventLabel    : cid
        });
    });
}
