import m from "mithril";

import modalContainer from "../components/modal-container";

export default {
    view(vnode) {
        const { i18n, name } = vnode.attrs;

        return m(modalContainer,
            m("div", { "data-test" : `${name}-modal` },
                m("h3[data-test=success]", i18n.header),
                i18n.body && m("p", m.trust(i18n.body)),
                vnode.children
            )
        );
    }
};
