/**
 * Array of all input types that `.value` is a safe assumption
 */
const inputTypesWithValue = [
    "input:",
    "input:tel",
    "input:text",
    "input:hidden",
    "input:file",
    "input:password",
    "input:number",
    "input:email",
    "textarea:",
    "textarea:textarea",
    "select:",
    "select:select-one"
];

/**
 * Extract important data from field element and return as object
 * @param {HTMLFormElement} field field object to extract data from
 * @returns {{ type : string, name : string, value : value }} most important field data
 */
function getValues(field) {
    const fieldType      = field.getAttribute("type");
    const fieldCheckbox2 = field.getAttribute("data-checkbox2") !== null; // collect data differently (comm prefs)
    const fieldData      = {
        type : `${field.nodeName.toLowerCase()}:${fieldType ? fieldType.toLowerCase() : ""}`,
        name : field.getAttribute("name")
    };

    if (inputTypesWithValue.includes(fieldData.type)) {
        fieldData.value = field.value;
    } else if (fieldData.type === "select:select-multiple") {
        fieldData.value = Array.prototype.filter.call(field.options, opt => opt.selected);
    } else if (fieldData.type === "input:radio" || (fieldData.type === "input:checkbox" && !fieldCheckbox2)) {
        if (field.checked) {
            fieldData.value = field.value;
        } else {
            delete fieldData.name;
        }
    } else if (fieldData.type === "input:checkbox" && fieldCheckbox2) {
        fieldData.value = field.checked.toString();
    }

    return fieldData;
}

/**
 * Given a form, return all of the relevant field values as an object
 * @param {HTMLElement} form form containing fields to return values from
 */
export default function getFormValues(form) {
    const formValues = {};

    if (form.nodeName.toLowerCase() !== "form") {
        return false;
    }

    Array.prototype.forEach.call(form.elements, field => {
        // extract data we care about
        const fieldData = getValues(field);

        // add field value into formValues
        if (fieldData.value && fieldData.name in formValues) {
            if (!Array.isArray(formValues[fieldData.name])) {
                formValues[fieldData.name] = [ formValues[fieldData.name] ];
            }

            formValues[fieldData.name].push(fieldData.value);
        } else if (fieldData.name) {
            formValues[fieldData.name] = fieldData.value;
        }
    });

    return formValues;
}
