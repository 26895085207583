import m from "mithril";

import formField from "../../../../components/form-field";
import a         from "../../../../components/anchor";
import tooltip   from "../../../../components/tooltip";

import preserveParams from "../../../../util/preserve-params";
import handleForm     from "../../../../util/handle-form";

import globalState from "../../../../state";

import css from "../index.css";

export default {
    view({ attrs }) {
        const fromRegister = m.route.get().includes("register");
        const phone        = m.route.param("phone");
        const type         = m.route.param("type");
        const secret       = globalState.shared_secret;
        const i18n         = globalState.i18n.twostep.signup[attrs.linkType].link;
        const sms          = attrs.linkType === "sms";

        return m("form.pure-form", handleForm({
                method : "POST",
                action : preserveParams(`/security/2-step/${attrs.linkType}/link`, "alt")
            }),
            m(`input[type=hidden][name=_formName][value=${attrs.linkType}-link]`),

            // Plumb through to re-populate prev step upon failures, this was done in a rush
            sms ?
                [
                    m("input[type=hidden][name=phone]", { value : phone }),

                    m("input[type=hidden][name=type]",  { value : type })
                ] :
                m("input[type=hidden][name=secret]", { value : secret }),

            m(formField, {
                name         : "otp",
                labelClass   : "pure-u-1",
                strings      : globalState.i18n.forms[`${attrs.linkType}-link`].otp,
                maxlength    : sms ? 5 : 6,
                autocomplete : "one-time-code"
            }),

            m(formField, {
                name    : "allowlist",
                strings : globalState.i18n.forms[`${attrs.linkType}-link`].allowlist,
                type    : "checkbox",
                value   : true
            }),

            m("a", { class : css.allowlistInfo },
                i18n.allowlist,

                m(tooltip, { style : css.tooltip }, m.trust(i18n["allowlist-info"]))
            ),

            m("div", { class : css.stepFt },
                fromRegister ?
                    m(a, {
                            class : css.cancel,
                            href  : preserveParams("/security/2-step/later", "alt")
                        },
                        globalState.i18n.twostep.registered.cancel
                    ) :
                    null,

                m("button[type=submit].pure-button.pure-button-primary", { class : css.next },
                    globalState.i18n.twostep.next
                )
            )
        );
    }
};
