"use strict";

const html = {
        "&"  : "&amp;",
        "<"  : "&lt;",
        ">"  : "&gt;",
        "\"" : "&quot;",
        "'"  : "&#x27;",
        "/"  : "&#x2F;",
        "`"  : "&#x60;"
    };

function _replacer(match) {
    return html[match];
}

exports.html = function(string) {
    return (`${string}`).replace(/[&<>"'\/`]/g, _replacer);
};

exports.regex = function(string) {
    return (`${string}`).replace(/[\-$\^*()+\[\]{}|\\,.?\s]/g, "\\$&");
};

// Aliasing
exports.regexp = exports.regex;
