import m from "mithril";

import handleForm from "../../../util/handle-form";

import globalState from "../../../state";

import css from "../index.css";

const i18n = globalState.i18n.allowlist.add;

export default {
    oninit({ state }) {
        state.allow = false;
    },

    view({ state }) {
        const mode = globalState.result;

        if (mode === "error") {
            return m(".module.pure-u-1.pure-u-sm-1-2",
                m("h2", i18n.failure),
                m("p", i18n.result.failure)
            );
        }

        if (mode === "success") {
            return m(".module.pure-u-1.pure-u-sm-1-2",
                m("h2", i18n.success),
                m("p", i18n.result.success)
            );
        }

        return m(".module.pure-u-1.pure-u-sm-1-2", { class : css.allowNetwork },
            m("form.pure-form.inner[method=post][action=/login/allow]",
                handleForm(),
                m("input[type=hidden][name=_formName][value=login/allow]"),

                m("input[type=hidden][name=token]", { value : globalState.token }),

                m("input[type=hidden][name=request]", { value : globalState.request }),

                m("input[type=hidden][name=ip]", { value : globalState.ip }),

                m("h2", i18n.heading),

                m("p", i18n.info),

                m("p", m.trust(i18n.address), ` ${globalState.ip}`),

                m("label.field.pure-u-1",
                    m("input[type=checkbox][name=allowlist]"),
                    i18n.remember
                ),

                m("input[type=hidden]", {
                    value : state.allow,
                    name  : "allow"
                }),

                m("button.pure-button.pure-button-primary[type=submit][name=allow][value=true]", {
                    onclick() {
                        state.allow = true;
                    }
                }, i18n.submit),

                m("button.pure-button.pure-button-primary[type=submit][name=allow][value=false]", {
                    onclick() {
                        state.allow = false;
                    }
                }, i18n.deny)
            )
        );
    }
};
