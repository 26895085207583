import m from "mithril";

import globalState from "../../state";

import css from "./index.css";

function rand(max) {
    return Math.floor(Math.random() * max);
}

export default {
    oninit({ state }) {
        state.quaggans = [];

        state.possibleQuaggans = m.request({ url : "https://api.guildwars2.com/v2/quaggans" });

        state.addQuaggan = () => {
            state.possibleQuaggans.then(quaggans => {
                state.quaggans.push(
                    quaggans[rand(quaggans.length)]
                );
            });
        };
    },

    view : ({ state }) =>
        m(".pure-g",
            m(".pure-u-sm-20-24.pure-u-1.module",

                m("h3", globalState.i18n.fourohfour.foo),

                m(".pure-u-sm-20-24.pure-u-md-1-3.pure-u-1.leftCol", [
                    m.trust(globalState.i18n.fourohfour.desc)
                ]),

                m(".pure-u-sm-20-24.pure-u-md-2-3.pure-u-1",
                    m("div", {
                        class   : state.quaggans.length ? css.bigPartyQuaggan : css.quaggan,
                        onclick : state.addQuaggan
                    })
                ),

                m("div", { class : css.quagganParty }, state.quaggans.map(quaggan =>
                    m("img", {
                        class   : css.partyQuaggan,
                        src     : `https://static.staticwars.com/quaggans/${quaggan}.jpg`,
                        onclick : state.addQuaggan,
                        alt     : `Quaggan ${quaggan}`
                    })
                ))
            )
        )
};
