import m from "mithril";

/**
 * Given an allowlist of url parameters and a url, return a new url that contains all allowlisted parameters from current url
 * @param {string} url new url to append params to
 * @param {string|array} allowlistedParams params to be carried over to new url
 */
export default function preserveParams(url, allowlistedParams) {
    if (typeof allowlistedParams === "string") {
        allowlistedParams = [ allowlistedParams ];
    }

    const params = m.route.param();

    const filteredParams = m.buildQueryString(allowlistedParams.reduce((acc, curr) => {
        if (Object.keys(params).includes(curr)) {
            acc[curr] = params[curr];
        }

        return acc;
    }, {}));

    return url + (filteredParams ? `?${filteredParams}` : "");
}
