import m from "mithril";

import a from "../../../components/anchor";

import game from "./game";

import globalState from "../../../state";

import css from "../index.css";

const pageI18n = globalState.i18n.overview;
const gcI18n   = globalState.i18n.gameContent;

export default {
    view() {
        return m("div.pure-u-1.pure-u-md-1-2", { class : css.middleColumn },
            m("div.pure-u-1.pure-u-md-1.pure-u-sm-20-24", { class : css.module },
                m(a, {
                        href  : "/content",
                        class : css.manage
                    },
                    gcI18n.manage
                ),

                m("h3", pageI18n.accounts),

                !globalState.loading || globalState.gw2Features ?
                    m("ul",
                        m(game, {
                            game  : "gw2",
                            owned : globalState.hasGw2,
                            cta   : globalState.hasGw2 ? gcI18n.downloadClient : globalState.i18n.register.link,
                            href  : globalState.hasGw2 ? "/content" : "/register"
                        }),

                        Object.entries(globalState.gw2Features)
                            .filter(([ key, value ]) => value.isExp && ![ "hot", "pof" ].includes(key))
                            .reverse()
                            .map(([ key, value ]) => m(game, {
                                game  : key,
                                owned : value.owned,
                                pp    : value.isPP,
                                inc   : !value.owned
                            })),

                        m(game, {
                            game  : "pof",
                            owned : globalState.gw2Features.pof?.owned,
                            inc   : !globalState.gw2Features.pof?.owned && !globalState.gw2Features.hot?.owned
                        }),

                        m(game, {
                            game  : "hot",
                            owned : globalState.gw2Features.hot?.owned,
                            cta   : globalState.get("i18n.overview.hot.buy"),
                            inc   : !globalState.gw2Features.pof?.owned && !globalState.gw2Features.hot?.owned
                        }),

                        m(game, {
                            game  : "gw1",
                            owned : globalState.hasGw1
                        })
                    ) :
                    null
            )
        );
    }
};
