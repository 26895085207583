import m from "mithril";

/**
 * Given a string `str`, replace all occurrences of `{some-string}` with mithril objects
 * @param {string} attrs.str loc string to match through
 * @param {object} attrs.inner object to match strings to insert form
 */
export default {
    view : ({ attrs }) => attrs.str
        .split(/\{(.+?)\}/g)
        .map(match => (attrs.inner[match] ? m(attrs.inner[match]) : match))
};
