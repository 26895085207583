import m         from "mithril";
import Clipboard from "clipboard";

import accountLinking from "./account-linking";
import apiKeys        from "./api-keys";

import css from "./index.css";

export default {
    view() {
        return m("div", {
                class       : css.applications,
                "data-test" : "applicationsContainer",
                oncreate() {
                    // Adds a delegate to listen for copy
                    new Clipboard(".key-copy");
                }
            },

            m("div.pure-u-sm-20-24.pure-u-md-1-3.pure-u-1.leftCol",
                m(accountLinking)
            ),

            m("div.pure-u-1.pure-u-sm-20-24.pure-u-md-2-3.rightCol",
                m(apiKeys)
            )
        );
    }
};
