import m from "mithril";

import modalContainer from "../components/modal-container";
import successModal   from "../success";

import * as hideable from "../../hideable";
import formField     from "../../form-field";

import handleForm  from "../../../util/handle-form";
import globalState from "../../../state";
import schemas     from "../../../preload/schemas";

const i18n = globalState.i18n;

const schemaName = "change-accountname";

// add new test for uniqueness
schemas[schemaName].email.push(o => o._value !== globalState.user.login_name);

// search help: change email change login name

export default {
    view() {
        if (globalState.postSuccess) {
            return m(successModal, {
                i18n : {
                    header : globalState.i18n.forms["change-email"].success.header,
                    body   : globalState.i18n.forms["change-email"].success.body.replace("{email}", globalState.user.login_name)
                }
            });
        }

        return m(modalContainer,
                m("h3", i18n["password-reset"].confirm.changelogin.heading),
                m("form.pure-form[method=post][action=/modals/change-account-name][data-test=accountname-modal]",
                    handleForm(),
                    m(`input[type=hidden][name=_formName][value=${schemaName}]`),
                    m("div.pure-u-1",
                        m("p",
                            m.trust(i18n.forms["change-email"].instruction)
                        ),
                        m(formField, {
                            name         : "email",
                            labelClass   : "pure-u-1 pure-u-md-17-24",
                            required     : true,
                            type         : "text",
                            strings      : i18n.forms["change-email"].email,
                            autofocus    : true,
                            autocomplete : false
                        }),
                        m(hideable.input, {
                            name        : "password",
                            labelClass  : "pure-u-1 pure-u-md-17-24",
                            required    : true,
                            strings     : i18n.forms.password,
                            toggleTitle : i18n.forms.password.toggle
                        }),
                    ),
                    m("span",
                        m("button[type=submit][data-test=accountname-submit].pure-button.pure-button-primary",
                            globalState.i18n.submit
                        )
                    )
                )
        );
    }
};
