import m from "mithril";

import detectOs        from "../../../util/detect-os";
import getDownloadLink from "../../../util/get-download-link";

import install from "./gw2-downloads/install";

import globalState from "../../../state";

import css from "../index.css";

const gcI18n = globalState.i18n.gameContent;

export default {
    oninit({ state }) {
        const os = detectOs();

        state.clients             = [];
        state.nonGw2BranchClients = [];

        globalState.gameList.forEach(client => {
            if (client.branch !== "gw2") {
                state.nonGw2BranchClients.push(client);

                return;
            }

            if (client.os === os.type && client.bits === os.bits) {
                state.bestClient = client;

                return;
            }

            state.clients.push(client);
        });

        if (!state.bestClient) {
            state.bestClient = state.clients.shift();
        }
    },

    view({ state }) {
        return m("div", { class : css.gw2Content },

            m(install, { bestClient : state.bestClient }),

            // min sys reqs
            m("p", { class : css.minSpec },
                m("a", { href : globalState.get("urls.minSysReqs") }, gcI18n.minimum[state.bestClient.os])
            ),

            // internal/alpha download links
            state.nonGw2BranchClients.length ?
                [
                    m("h3", { class : css.branchTitle },
                        // TODO: Untranslated string, fine since it's alpha-only
                        `${gcI18n.branches.title} - Internal`
                    ),

                    m("div", { class : css.branchClients },
                        state.nonGw2BranchClients.map(client =>
                            m("a", {
                                    class : css.dlBtn,
                                    href  : getDownloadLink(client)
                                },
                                `${client.branch.toUpperCase()} ${client.os}${client.bits}`
                            )
                        )
                    )
                ] :
                null
        );
    }
};
