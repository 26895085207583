import m from "mithril";

import a from "../../../components/anchor";

import globalState from "../../../state";

import css from "./index.css";

function resolveClass(routePrefix) {
    return m.route.get().startsWith(routePrefix) ? css.selected : css.notSelected;
}

export default {
    view() {
        const i18n = globalState.i18n.securityNav;

        return m("nav", { class : css.securityNav },
            m(a, {
                    href  : "/security/settings",
                    class : resolveClass("/security/settings")
                },
                i18n.settings
            ),

            !globalState.user.email_verified ?
                m(a, {
                        href  : "/security/email",
                        class : resolveClass("/security/email")
                    },
                    i18n.email
                ) :
                null,

            m(a, {
                    href  : globalState.twoStepUrl(),
                    class : resolveClass("/security/2-step")
                },
                i18n["2-step"]
            )
        );
    }
};
