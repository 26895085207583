import m         from "mithril";
import { sub }   from "template";
import mailcheck from "mailcheck";

import formField from "../../components/form-field";
import social    from "../../components/social";

import handleForm from "../../util/handle-form";

import success from "./success";
import error   from "./error";

import globalState from "../../state";

import css from "./index.css";

const i18n     = globalState.i18n.unsubscribe;
const formI18n = globalState.i18n.forms.unsubscribe;

export default {
    oninit({ state }) {
        state.emailSuggestion = "";
    },

    view : ({ state }) =>
        m("div", { class : css.unsubContent },
            m(".pure-u-sm-3-4.pure-u-1",

                m("div",
                    // show unsub form, or success/error
                    /* eslint no-nested-ternary: "off" */
                    !globalState.unsubscribed ?
                        [
                            m("h2", i18n.header),

                            m("form.pure-form", handleForm({
                                    class  : css.unsubForm,
                                    method : "post",
                                    action : "/unsubscribe"
                                }),
                                m("input[type=hidden][name=_formName][value=unsubscribe]"),

                                m(formField, {
                                    name         : "email",
                                    labelClass   : "pure-u-1",
                                    strings      : formI18n.email,
                                    autocomplete : "email",
                                    type         : "email",
                                    onchange     : m.withAttr("value", email => {
                                        const suggestion = mailcheck.run({ email });

                                        state.emailSuggestion = suggestion ? suggestion.full : "";
                                    })
                                }),

                                m("div", { class : css.suggestion },
                                    state.emailSuggestion ?
                                        m.trust(sub(formI18n.emailSuggestion, { email : state.emailSuggestion })) :
                                        null
                                ),

                                m("button[type=submit].pure-button.pure-button-primary", formI18n.submit)
                            )
                        ] :
                        // show success or error
                        globalState.unsubscribed.success ?
                            m(success) :
                            m(error)
                ),

                m(social),

                m("p", { class : css.unsubDisc }, m.trust(i18n.disc))
            )
        )
};
