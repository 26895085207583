"use strict";

const object = require("./object");
const escape = require("./escape");

const subRegex = /\{\s*([^\|\}]+?)\s*(?:\|([^\}]*))?\s*\}/g;

function compile(text, options) {
    const blocks = [];
    const tokenClose = "\uffff";
    const tokenOpen  = "\ufffe";

    options = object.merge(exports.options, options);

    const source =
        `${"data || (data = {});" +
        "var $b='', $v=function(v){return v || v === 0 ? v : $b;}, $t='"}${
        (`${text}`)
            .replace(/\ufffe|\uffff/g, "")
            .replace(options.rawOutput, function(match, code) {
                return tokenOpen + (blocks.push(`'+\n$v(${code})+\n'`) - 1) + tokenClose;
            })
            .replace(options.escapedOutput, function(match, code) {
                return tokenOpen + (blocks.push(`'+\n$e($v(${code}))+\n'`) - 1) + tokenClose;
            })
            .replace(options.code, function(match, code) {
                return tokenOpen + (blocks.push(`';\n${code}\n$t+='`) - 1) + tokenClose;
            })
            .replace(options.stringEscape, function(match) {
                return options.stringReplace[match] || "";
            })

            // Replace the token placeholders with code.
            .replace(/\ufffe(\d+)\uffff/g, function(match, index) {
                return blocks[parseInt(index, 10)];
            })

            // Remove noop string concatenations that have been left behind.
            .replace(/\n\$t\+='';\n/g, "\n")
            }';\nreturn $t;`;

    return source;
}

// Exposed for testing
exports._compile = compile;

exports.compile = function(text, options) {
    /* jshint evil:true */
    const source = compile(text, options);

    return (new Function("$e", "data", source)).bind(null, escape.html);
};

exports.options = {
    code          : /<%([\s\S]+?)%>/g,
    escapedOutput : /<%=([\s\S]+?)%>/g,
    rawOutput     : /<%==([\s\S]+?)%>/g,
    stringEscape  : /\\|'|\r|\n|\t|\u2028|\u2029/g,

    stringReplace : {
        "\\"     : "\\\\",
        "'"      : "\\'",
        "\r"     : "\\r",
        "\n"     : "\\n",
        "\t"     : "\\t",
        "\u2028" : "\\u2028",
        "\u2029" : "\\u2029"
    }
};

// Simple templating using a regex
exports.sub = function(src, data) {
    if (!src.replace || typeof data !== "object") {
        return src;
    }

    return src.replace(subRegex, function(match, key) {
        let value;

        if (key.indexOf(".") > -1) {
            value = object.get(data, key);

            return (typeof value !== "undefined") ? value : match;
        }

        return (typeof data[key] !== "undefined") ? data[key] : match;
    });
};
