import m from "mithril";

import formField from "../../components/form-field";
import formLogo  from "../../components/form-logo";

import handleForm from "../../util/handle-form";

import globalState from "../../state";

import css from "./index.css";

const i18n     = globalState.i18n["password-reset"];
const formI18n = globalState.i18n.forms["password-reset"];

export default {
    view : () =>
        m("div", { class : css.passwordResetModule },
            m("h3",
                m(formLogo),
                i18n.heading
            ),

            m("form.pure-form[method=post][action=/password-reset]",
                handleForm(),
                m("input[type=hidden][name=_formName][value=password-reset]"),

                m(formField, {
                    name       : "email",
                    type       : "email",
                    strings    : formI18n.email,
                    labelClass : "pure-u-1 pure-u-md-17-24"
                }),

                m("button[type=submit].pure-button.pure-button-primary", { class : css.passwordResetBtn },
                    i18n.button
                )
            )
        )
};
