import m from "mithril";

import handleForm from "../../util/handle-form";

import globalState from "../../state";

const i18n = globalState.i18n.allowlist.add;

export default {
    view() {
        const netAddr = m.route.param("netaddr");

        return m(".module.pure-u-1.pure-u-sm-1-2",
            m("form.pure-form.inner[method=post][action=/allowlist]",
                handleForm(),
                m("input[type=hidden][name=_formName][value=allowlist]"),

                m("input[type=hidden][name=userid]", { value : m.route.param("userid") }),

                m("input[type=hidden][name=sessid]", { value : m.route.param("sessid") }),

                m("input[type=hidden][name=netaddr]", { value : netAddr }),

                m("h2", i18n.heading),

                m("p", i18n.info),

                m("p", m.trust(i18n.address), ` ${netAddr}`),

                m("button.pure-button.pure-button-primary", i18n.submit)
            )
        );
    }
};
