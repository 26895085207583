import m from "mithril";

import modalContainer from "../components/modal-container";

import handleForm from "../../../util/handle-form";
import gamesight  from "../../../util/gamesight";

import formField from "../../form-field";

import globalState from "../../../state";

import css from "./index.css";

const i18n     = globalState.i18n.addCode;
const formI18n = globalState.i18n.forms.addCode;

export default {
    oninit({ state }) {
        state.helpLinks = [{
            text : i18n.link1,
            href : globalState.get("urls.findingCodes")
        }, {
            text : i18n.link3,
            href : globalState.get("urls.redeemingCodes")
        }];
    },

    view({ state }) {
        const itemsAdded    = globalState.added || [];
        const { helpLinks } = state;

        return m(modalContainer, { class : css.addCode },

            globalState.added ?
                // Success, lists what was added
                [
                    m("h3", itemsAdded.length ? i18n.successTitle : i18n.noItemsTitle),

                    m("ul", { class : css.addedItems },
                        itemsAdded.map(item => m("li", item))
                    ),

                    itemsAdded.length ? m("p", { class : css.disclaimer }, i18n.disclaimer) : null,

                    m("button", {
                            class : css.another,
                            type  : "button",
                            onclick() {
                                delete globalState.added;
                            }
                        },
                        globalState.i18n.overview.links.button
                    )
                ] :

                // Pending, code input
                [
                    m("h3", i18n.title),

                    m("form[method=post][action=/modals/add-code]",

                        handleForm({
                            class : css.form,
                            onSuccess() {
                                gamesight("send", "account_anet_redeem_success");
                            }
                        }),

                        m("input[type=hidden][name=_formName][value=add-code]"),

                        m(formField, {
                            name         : "code",
                            strings      : formI18n.code,
                            labelClass   : "pure-u-1 pure-u-md-15-24",
                            autofocus    : true,
                            autocomplete : false
                        }),


                        m("button", {
                                class : css.submit,
                                type  : "submit"
                            },
                            globalState.i18n.submit
                        )
                    ),

                    m("div",
                        m("p", { class : css.helpHd }, i18n.help),

                        helpLinks.map(({ text, href }) =>
                            m("a", {
                                    href,
                                    class  : css.help,
                                    target : "_blank",
                                    rel    : "noopener noreferrer"
                                },
                                text
                            )
                        )
                    )
                ]
        );
    }
};
