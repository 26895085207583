import m from "mithril";

import hide from "../../../../util/hide-components";

import globalState from "../../../../state";

import css from "./index.css";

export default {
    view() {
        return hide() ?
            null :
            m("div", { class : css.headerArt },
                m("div", { class : css.constrained },
                    m("a", {
                            class    : css.anetLogo,
                            oncreate : m.route.link,
                            href     : globalState.loggedIn ? "/overview" : "/login"
                        },
                        m("img", {
                            src : "https://account.staticwars.com/img/logo-red-white.svg",
                            alt : "ArenaNet"
                        })
                    )
                )
            );
    }
};
