const debug = false;

export default function ga(o) {
    if (debug) {
        console.log(o);
    }

    window.dataLayer = window.dataLayer || [];

    window.dataLayer.push(o);
}
