/**
 * Given a string uri, parse out all data
 * This is sort of strange and relying on html elements but whatevs
 * @param {string} uri Uri to parse
 * @return {object} host, path, and search
 */
export default function parseUri(uri) {
    const anchor = document.createElement("a");

    anchor.href = uri;

    return { host : anchor.host, path : anchor.pathname, search : anchor.search };
}
