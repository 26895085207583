import m         from "mithril";
import cssjoiner from "cssjoiner";

import globalState from "../../../state";

import ga from "../../../util/ga";

import css from "../index.css";

const gcI18n = globalState.i18n.gameContent;
const smI18n = globalState.i18n.overview;


const divOrLink = {
    view({ attrs, children }) {
        return attrs.href ?
            m("a", {
                    class : attrs.class,
                    href  : attrs.href,

                    onclick() {
                        ga({
                            event         : "gtm-upgrade",
                            eventCategory : "upgrade",
                            eventAction   : "upgrade-click",
                            eventLabel    : `upgrade-${attrs.game}`
                        });
                    }
                },
                children
            ) :
            m("div", attrs, children);
    }
};

export default {
    view({ attrs }) {
        const href = attrs.href || globalState.getBuyLink(attrs.game);
        const cta  = attrs.pp ? globalState.get("i18n.gameContent.prepurchase") : attrs.cta;

        let included;

        if (attrs.inc) {
            included = attrs.pp ? smI18n[attrs.game].pp : smI18n[attrs.game].inc;
        }

        return m("li", {
                class : cssjoiner(
                    css[`game-${attrs.game}`],
                    [ attrs.owned, css.owned, css.notOwned ],
                    css.gameItem
                )
            },

            m(divOrLink, {
                    href : !attrs.owned && href,
                    game : attrs.game
                },

                m("div", { class : css.gameArt }),

                m("div", { class : css.game },
                    // "Includes HOT/Included with POF"
                    included ?
                        m("p", { class : css.inc }, included) :
                        null,

                    // "GW2: HOT/POF", etc
                    m("p", { class : css.title }, gcI18n[attrs.game].title),

                    // "Active/Upgrade/Buy"
                    attrs.owned && !attrs.upgrade && attrs.game !== "gw2" ?
                        m("div", { class : css.ownedLink }, gcI18n.owned) :
                        m(divOrLink, {
                                class : css.modLink,
                                href  : attrs.owned && href,
                                game  : attrs.game,
                                owned : attrs.owned
                            },
                            cta ? m.trust(cta) : gcI18n.buy // m.trust(undefined) is truthy!
                        )
                )
            )
        );
    }
};
