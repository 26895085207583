import m from "mithril";

import globalState from "../../../../../state";

const i18n = globalState.i18n.wait.allowlist;

export default {
    view() {
        return m(".module.pure-u-1.pure-u-md-2-3",
            m("h2", i18n.header),

            m("p", i18n.body)
        );
    }
};
