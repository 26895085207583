import m from "mithril";

import stepContainer from "../2-step/step-container";

import emailVerify from "./email-verify-link";

import globalState from "../../../state";

import css from "../2-step/index.css";

const i18n = globalState.i18n.email.verify;

export default {
    view : () => 
        m(stepContainer,
            m("p", { class : css.stepInfo }, i18n.info),
            m("h3", i18n.title),

            m("p", i18n.body1),
            
            m(emailVerify, {
                button : true,
                text   : i18n.button
            })
        )
};
