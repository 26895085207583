import m from "mithril";

import globalState from "../../state";

import css from "./index.css";

const i18n = globalState.i18n.social;

export default {
    oninit(vnode) {
        vnode.state.links = Object.entries(i18n.links);
    },
    view(vnode) {
        return m(".module",
            m("h3", i18n.header),

            m("div", { class : css.socials },
                vnode.state.links.map(([ link, href ]) =>
                    m("a", { class : css[link], href }, link)
                )
            )
        );
    }
};
