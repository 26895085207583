(function(root, form) {
    "use strict";

    // small polyfill for Y.Lang
    var L = {
        isValue : function(val) {
            var type = typeof val;

            if (val === null) {
                type = "null";
            }

            switch (type) {
                case "number":
                    return isFinite(val);

                case "null": // fallthrough
                case "undefined":
                    return false;

                default:
                    return Boolean(type);
            }
        },

        merge : function() {
            var i = 0,
                len = arguments.length,
                result = {},
                key, obj;

            for (; i < len; ++i) {
                obj = arguments[i];

                for (key in obj) {
                    if (obj.hasOwnProperty(key)) {
                        result[key] = obj[key];
                    }
                }
            }

            return result;
        }
    };

    function _fail() {
        return false;
    }

    function _test(test) {
        // custom function test
        if (typeof test === "function") {
            return test;
        }

        // built-in test
        if (form._tests[test]) {
            return form._tests[test];
        }

        (console.error || console.log)("Unknown test " + JSON.stringify(test) + "\n{" + (new Error().stack) + "}");

        return _fail;
    }

    function _regexTest(o) {
        if (typeof o._value !== "string") {
            return false;
        }

        return form.regexes[o.test].test(o._value);
    }

    function _optional(test) {
        return test.optional && (!L.isValue(test._value) || test._value === "");
    }

    form = {
        regexes : {
            email  : /\S+@\S+\.\S{2,}$/,
            guid   : /^[A-Fa-f0-9]{32}$|^(\{|\()?[A-Fa-f0-9]{8}-([A-Fa-f0-9]{4}-){3}[A-Fa-f0-9]{12}(\}|\))?$/,
            login  : /\S+@(?:\S+\.\S+|ncsoft|plaync|ncjapan)$|\\/,
            digits : /^\d+$/
        },

        _error : "__gw2-error__",

        _tests : {
            email  : _regexTest,
            guid   : _regexTest,
            login  : _regexTest,
            digits : _regexTest,

            int : function(o) {
                return !isNaN(parseInt(o._value, 10));
            },

            number : function(o) {
                return !isNaN(Number(o._value));
            },

            compare : function(o) {
                /* eslint eqeqeq:off */
                var value = o._value,
                    other = L.isValue(o.compare) ? o.compare : this[o.field],
                    result;

                // optional tests shouldn't try to compare to non-valuey things
                if (o.optional && !L.isValue(other)) {
                    return true;
                }

                switch (o.comparison) {
                    case "<":
                        result = (value < other);
                        break;

                    case "<=":
                        result = (value <= other);
                        break;

                    case ">":
                        result = (value > other);
                        break;

                    case ">=":
                        result = (value >= other);
                        break;

                    case "!=":
                        result = (value != other);
                        break;

                    case "===":
                        result = (value === other);
                        break;

                    case "==":
                        /* falls through */
                    default:
                        result = (value == other);
                        break;
                }

                return result;
            },

            length : function(o) {
                var value  = o._value,
                    length = o.length,
                    result;

                switch (o.comparison) {
                    case "<":
                        result = (value.length < length);
                        break;

                    case "<=":
                        result = (value.length <= length);
                        break;

                    case "==":
                        result = (value.length == length);
                        break;

                    case ">":
                        result = (value.length > length);
                        break;

                    case ">=":
                        /* falls through */
                    default:
                        result = (value.length >= length);
                        break;
                }

                return result;
            },

            passthrough : function() {
                return true;
            },

            value : function(o) {
                if (!L.isValue(o._value)) {
                    return false;
                }

                // Strings have to have some characters to be valid
                if (typeof o._value === "string") {
                    return Boolean(o._value.length);
                }

                return true;
            },

            boolString : function(o) {
                if (!L.isValue(o._value)) {
                    return false;
                }

                // must be "true" or "false"
                if (typeof o._value !== "string" || (o._value.toLowerCase() !== "true" && o._value.toLowerCase() !== "false")) {
                    return false;
                }

                return true;
            },

            schema : function(o) {
                var results;

                if (!o._value || !o.schema) {
                    return false;
                }

                results = form.validate(o._value, o.schema);

                return results.valid;
            }
        },

        // validate the data object using the passed-in schema
        validate : function(data, schema) {
            var results = {
                    valid  : false,
                    data   : {},
                    errors : {}
                };

            // garbage in, garbage out
            if (!data || typeof schema !== "object" || typeof data !== "object") {
                return results;
            }

            Object.keys(schema).forEach(function(field) { // eslint-disable-line max-statements
                var value = data[field],
                    test  = schema[field];

                // undefined value is a failure if the test isn't optional
                // if optional it's just removed from data object
                if (!L.isValue(value)) {
                    if (!test.optional) {
                        results.errors[field] = "invalid-data";
                    }

                    return;
                }

                // instead of cloning, just copy schema fields
                results.data[field] = value;

                if (Array.isArray(test) || Array.isArray(test.tests)) { // array is multiple tests for this field
                    // bail asap using .some()
                    (test.tests || test).some(function(o) {
                        if (typeof o === "string" || typeof o === "function") {
                            o = { test : o, _value : value };
                        } else if (typeof o === "object") {
                            o._value = value;
                        }

                        if (_optional(o)) {
                            return;
                        }

                        if (!_test(o.test).call(data, o)) {
                            results.errors[field] = o;

                            // stop iteration if we failed a test
                            return true;
                        }

                        // If it worked, keep checking all tests
                        return false;
                    });
                } else if (typeof test === "object") { // object is multiple args for the test
                    if (!test.test) {
                        test = {
                            test : test
                        };
                    }

                    test._value = value;

                    if (_optional(test)) {
                        return;
                    }

                    if (!_test(test.test).call(data, test)) {
                        results.errors[field] = test;
                    }
                } else { // string is a simple 'ol test
                    test = {
                        test   : test,
                        _value : value
                    };

                    if (!_test(test.test).call(data, test)) {
                        results.errors[field] = test;
                    }
                }
            });

            results.valid = !Object.keys(results.errors).length;

            return results;
        },

        // helper function to mark a field (and thus everything) as invalid
        error : function(result, field, test) {
            if (typeof result !== "object") {
                return result;
            }

            if (!result.errors) {
                result.errors = {};
            }

            result.valid = false;

            result.errors[field] = {
                test   : test || "force",
                _value : (result.data && L.isValue(result.data[field])) ? result.data[field] : null
            };

            return result;
        },

        // run a single test against a specific value
        test : function(test, value, data) {
            var o = L.merge(data || {}, {
                test   : test,
                _value : value
            });

            return _test(test).call(this, o);
        },

        add : function(name, test) {
            if (name in form._tests) {
                return false;
            }

            form._tests[name] = test;

            return true;
        },

        addRegex : function(name, regex) {
            var add = form.add(name, _regexTest);

            if (add) {
                form.regexes[name] = regex;
            }

            return add;
        }
    };

    if (typeof exports === "object") {
        module.exports = form;
    } else {
        root.form = form;
    }
}(this));
