import m from "mithril";

import formField      from "../../../components/form-field";
import passwordInputs from "../../../components/password-inputs";
import a              from "../../../components/anchor";

import handleForm from "../../../util/handle-form";

import globalState from "../../../state";

import css from "../index.css";

const i18n = globalState.i18n["password-reset"];

export default {
    view({ attrs }) {
        const changeAccountName = !m.route.param("u");

        return m("div", { class : css.passwordResetModule },
            globalState.postSuccess ? [
                m("h3", globalState.i18n.success),

                m("p", changeAccountName ?
                    (`${m.trust(i18n.confirm.changelogin.body)} ${attrs.values.email}`) :
                    i18n.confirm.success),

                m(a, {
                        class : "pure-button pure-button-primary",
                        href  : `/login?${attrs.values.email ? m.buildQueryString({ email : attrs.values.email }) : ""}`
                    },
                    i18n.confirm.button
                )
            ] : [
                m("h3", changeAccountName ? i18n.confirm.changelogin.heading : i18n.heading),

                m("form.pure-form[method=post][action=/password-reset/confirm]",
                    handleForm(),
                    m("input[type=hidden][name=_formName][value=password-reset-confirm]"),

                    m("input[type=hidden][name=s]", { value : m.route.param("s") }),

                    !changeAccountName ?
                        m("input[type=hidden][name=u]", { value : m.route.param("u") }) :
                        m(formField, {
                            tabindex   : 0,
                            name       : "email",
                            type       : "email",
                            strings    : globalState.i18n.forms["password-reset-confirm"].email,
                            labelClass : "pure-u-1 pure-u-md-17-24",
                            oncreate({ dom }) {
                                dom.focus();
                            }
                        }),

                    m(passwordInputs),

                    m("button[type=submit].pure-button.pure-button-primary", { class : css.passwordResetBtn },
                        changeAccountName ? globalState.i18n.submit : i18n.button
                    )
                )
            ]
        );
    }
};
