import m from "mithril";

import darkmode from "../../components/layout/components/dark-mode";

import globalState from "../../state";

import css from "./index.css";

import commPrefs   from "./components/comm-prefs";
import accountInfo from "./components/account-info";

const i18n = globalState.i18n.settings;

export default {
    view() {
        return m(".pure-g[data-test=settingsContainer]", { class : css.settings },
            m(accountInfo),

            m(commPrefs),

            m("div", { class : css.darkmode },
                m("div", { class : css.dmLabel }, i18n.darkmode),
                m(darkmode)
            )
        );
}
};
