import m         from "mithril";
import cssjoiner from "cssjoiner";

import securityNav from "../security-nav";

import css from "../settings/index.css";

export default {
    view : ({ attrs, children }) =>
        m("div", { class : css.security },
            m(securityNav),

            m("div", { class : cssjoiner("module pure-u-sm-20-24 pure-u-1", (attrs.stepCss || "")) },
                children
            )
        )
};
