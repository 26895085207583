import m from "mithril";

import globalState from "../../../state";

import a from "../../../components/anchor";

import handleForm from "../../../util/handle-form";

import header from "../components/header";

import css from "../index.css";

const i18n = globalState.i18n.apikeys;

export default {
    view() {
        const id = m.route.param("id");

        return m("div", { class : css.applications },
            m(".pure-u-1",
                m(header, { header : i18n.delete.header, desc : i18n.delete.desc },
                    m("form.pure-form", handleForm({
                            method : "post",
                            action : `/applications/${id}/delete`
                        }),

                        m("button.pure-button.pure-button-primary", { type : "submit" }, i18n.delete.submit),

                        m(a, {
                            href  : "/applications",
                            class : [ "pure-button", css.pureBtnSecondary ].join(" ")
                        }, i18n.delete.cancel)
                    )
                )
            )
        );
    }
};
