/**
 * Post data via a `<form>`, useful for JWT logins eg zendesk
 * @param {object} options
 * @param {string} options.action
 * @param {string} [options.method="POST"]
 * @param {object} options.fields
 * @returns {void}
 */
export function postForm({ action, method = "POST", fields }) {
    // build form element, populate with fields, and submit
    const form = document.createElement("form");

    form.method        = "post";
    form.action        = action;
    form.style.display = "none";

    for (const key in fields) {
        const input = document.createElement("input");

        input.type  = "hidden";
        input.name  = key;
        input.value = fields[key];

        form.appendChild(input);
    }

    document.body.appendChild(form);

    form.submit();
}
