import m from "mithril";

import formField from "../../../components/form-field";

import handleForm from "../../../util/handle-form";

import globalState from "../../../state";

import css from "../index.css";

export default {
    view : () => {
        const authType = m.route.param("auth_type") || "sms";
        const i18n     = globalState.i18n["password-reset"].auth[authType];
        const formI18n = globalState.i18n.forms[`password-reset-${authType}`];

        return m("div", { class : css.passwordResetModule },
            m("h3", i18n.heading),

            m("p", i18n.body),

            m("form.pure-form[method=post]", handleForm({
                    action : `/password-reset/auth/${authType}`
                }),
                m("input[type=hidden][name=_formName]", {
                    value : `password-reset-${authType}`
                }),

                m("input[type=hidden][name=s]", { value : m.route.param("s") }),

                m("input[type=hidden][name=email]", { value : m.route.param("email") }),

                m(formField, {
                    name       : "otp",
                    strings    : formI18n.otp,
                    labelClass : "pure-u-1 pure-u-md-17-24",
                    maxlength  : authType === "sms" ? 5 : 6
                }),

                m("button[type=submit].pure-button.pure-button-primary", { class : css.passwordResetBtn },
                    globalState.i18n.submit
                )
            )
        );
    }
};
