/* global random, round, floor, TWO_PI */

export default function Particle(opts) {
    const scale = random() > 0.1;

    if (!opts.regions) {
        return;
    }

    // get region
    const region = opts.regions[floor(random(0, opts.regions.length))];

    this.x = random(region.x1, region.x2);
    this.y = random(region.y1, region.y2);

    this.vx = random(-15, 0);
    this.vy = random(-18, -10);

    this.radius   = random(1, 2);
    this.lifespan = random(10000, 20000);
    this.charge   = this.lifespan;


    const r = round(scale ? random(255) : random(230, 255));

    this.color = {
        r : r,
        g : 100,
        b : 30
    };
}

Particle.prototype = {
    isDead() {
        return this.charge < 0 || this.radius < 0.5 || this.x < 0 || this.y < 0;
    },

    update(ctx) {
        this.charge -= (ctx.dt / 16);
        this.radius -= (0.0003 * ctx.dt);

        this.x += (this.vx * (0.06 * ctx.dt)) * 0.25;
        this.y += (this.vy * ctx.dt / 18) * 0.125;

        if (this.isDead()) {
            return false;
        }

        return true;
    },

    draw(ctx) {
        const opacity = round(this.charge * 100) / 50;
        let fill;

        if (this.radius > 8) {
            fill = ctx.createRadialGradient(this.x, this.y, 0, this.x, this.y, this.radius);

            fill.addColorStop(0,   `rgba(${this.color.r}, ${this.color.g}, ${this.color.b}, 1)`);
            fill.addColorStop(0.3, `rgba(${this.color.r}, ${this.color.g}, ${this.color.b}, 0.5)`);
            fill.addColorStop(1,   `rgba(${this.color.r}, ${this.color.g}, ${this.color.b}, 0)`);
        } else {
            // solid fill (for perf)
            fill = `rgba(${this.color.r}, ${this.color.g}, ${this.color.b}, ${opacity * 0.9})`;
        }

        ctx.fillStyle = fill;

        ctx.beginPath();
        ctx.arc(floor(this.x), floor(this.y), this.radius, TWO_PI, false);
        ctx.fill();
    }
};
