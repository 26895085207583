import m from "mithril";

import handleForm from "../../../../../util/handle-form";

import a              from "../../../../../components/anchor";
import formLogo       from "../../../../../components/form-logo";
import loadingSpinner from "../../../../../components/loading-spinner";

import passthroughParams from "../passthrough-params";

import globalState from "../../../../../state";

import css from "../../../index.css";

const i18n = globalState.i18n.wait.email;

export default {
    view : ({ attrs }) =>
        m(".module.pure-u-1.pure-u-md-1-2",
            m(".pure-g.pure-form", handleForm({
                    action : "/login/wait/email",
                    method : "post",
                    class  : css.anetLogoForm
                }),
                m(formLogo),

                m(passthroughParams),

                m("input[type='hidden'][name='_formName'][value='loginWait']"),

                m(".pure-u-1",
                    !attrs.failed ?
                        // waiting
                        m("div",
                            m("h2", i18n.waiting.header),

                            m("p", i18n.waiting.body),

                            m("noscript",
                                m("p", i18n.waiting.noscript)
                            ),

                            m(loadingSpinner)
                        ) :
                        // waiting failed
                        m("div",
                            m("h2", i18n.failed.header),

                            m("p", i18n.failed.body),

                            m("p",
                                m(a, {
                                        href  : "/login",
                                        class : "pure-button pure-button-primary"
                                    },
                                    i18n.failed.link
                                ),

                                m("img", {
                                    src   : "https://static.staticwars.com/quaggans/lollipop.jpg",
                                    style : "width: 100%; margin-bottom: -32px;",
                                    alt   : "Quaggan"
                                })
                            )
                        )
                )
            )
        )
};
