"use strict";

import m      from "mithril";
import stream from "mithril-stream";

import particles from "./particles";

import css from "../index.css";

export default {
    oninit : vnode => {
        vnode.state.play = stream(false);
    },

    oncreate : vnode => {
        function start() {
            vnode.state.particles = particles(vnode.dom);

            window.stream = vnode.state.particles;

            vnode.state.play.map(() => { // eslint-disable-line array-callback-return
                if (!vnode.state.particles) {
                    return; // eslint-disable-line array-callback-return
                }

                vnode.state.particles.start();
            });
        }

        start();
    },

    view : () =>
        m("div", { class : css.canvasContainer })
};
