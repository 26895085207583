import m from "mithril";

import css from "../../index.css";

export default {
    view({ attrs, children }) {
        return m(".module.pure-u-1", { class : css.module },
            m("h3", attrs.header),
            m("p", {
                    onclick(e) {
                        const href = e.target.getAttribute("href") || "";

                        if (href.charAt(0) === "/") {
                            e.preventDefault();
                            m.route.set(href);
                        }
                    }
                },
                m.trust(attrs.desc)
            ),

            children
        );
    }
};
