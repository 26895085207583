import m from "mithril";

import globalState from "../../state";

import error from "../../components/error";

export default {
    oninit() {
        if (!globalState.errorMessage) {
            // force a "real" page load to hit backend and redirect oauth login
            window.location = m.route.get();
        }
    },
    view() {
        return globalState.errorMessage ? m(error) : null;
    }
};
