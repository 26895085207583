import m from "mithril";

import globalState from "../../../../state";

import css from "../../../layout/components/modal-overlay/index.css";

const i18n = globalState.i18n;

export default {
    view : ({ attrs, children }) =>
        m("div", { class : [ "module", attrs.class ].join(" ") },

            m("button", {
                    class : css.closeOverlay,
                    onclick(e) {
                        e.preventDefault();

                        globalState.modal.close();
                        globalState.form.clearError();

                        globalState.postSuccess = false;
                    },
                    "aria-label" : i18n.ui.close
                },
                "🗙"
            ),

            children
        )
};
