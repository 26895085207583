import m from "mithril";

import tooltip from "../../components/tooltip";

import globalState from "../../state";

import errIcon from "./errIcon";

import css from "./index.css";

const tooltipExtra = {
    // todo: likely should be a button (suggest pw)
    view : ({ attrs }) => m(attrs.attrs.href ? "a" : "button", attrs.attrs, attrs.strings)
};

const filteredAttrs = [
    "strings",
    "labelClass",
    "tooltipExtra"
];

function mergeAttrs(state, _attrs) {
    const attrs = Object.assign(state.defaultAttrs, _attrs);
    let error;

    filteredAttrs.forEach(attr => {
        delete attrs[attr];
    });

    // checkboxes are weird, they have to be restored manually
    // todo: figure out where this comes into play, can it be removed?
    if (attrs.type === "checkbox" && attrs.name in globalState.form.data) {
        attrs.checked = Boolean(globalState.form.data[attrs.name]);
    }

    if (attrs.name in globalState.form.errors) {
        attrs.class = "error";
        error       = true;
    }

    return { attrs, value : attrs.value, error };
}

export default {
    oninit : ({ state, attrs }) => {
        const {
            strings = {}
        } = attrs;

        // for tooltips that show up on input field focus
        state.focused      = attrs.autofocus;
        state.checkOrRadio = attrs.type === "checkbox" || attrs.type === "radio";

        state.defaultAttrs = {
            type        : "text",
            placeholder : state.checkOrRadio ? "" : strings.label,
            value       : attrs.type === "checkbox" ? "on" : attrs.value,
            onfocus() {
                state.focused = true;
            },
            onfocusout() {
                state.focused = false;
            }
        };
    },

    oncreate({ dom, attrs }) {
        if (attrs.autofocus) {
            dom.focus();
        }
    },

    /* eslint complexity : ["warn"] */
    view({ state, attrs, children }) {
        const { attrs : _attrs, value, error } = mergeAttrs(state, attrs);

        return m("label", { class : `field ${attrs.labelClass}`, "data-error" : Boolean(error) },

            // The actual input!!
            m(`input[data-test=${attrs.name}-input].pure-u`, _attrs, value),

            // just an error icon, no tooltip
            error ?
                m(errIcon) :
                null,

            // on error tooltip
            error && attrs.strings.error && state.focused ?
                m(tooltip,
                    attrs.strings.error[globalState.form.errors[attrs.name].test] ||
                    attrs.strings.error[attrs.name]
                ) :
                null,

            // onfocus tooltip
            attrs.strings && attrs.strings.rules && !error ?
                m(tooltip, { style : state.focused ? css.rulesFocused : css.rules },
                    attrs.strings.rules,

                    attrs.tooltipExtra ?
                        m(tooltipExtra, attrs.tooltipExtra) :
                        null
                ) :
                null,

            state.checkOrRadio ?
                m("div.labelText.pure-u", attrs.label || m.trust(attrs.strings.label)) :
                null,
            children
        );
    }
};
