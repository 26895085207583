import m from "mithril";

import anetdimebar from "anetdimebar";

import globalState from "../../../../state";

export default {
    view() {
        const route    = m.route.get();
        const register = route.includes("register");
        const login    = route.includes("login");

        return m(anetdimebar, {
            lang  : globalState.lang,
            langs : [],
            login : !globalState.loggedIn && !login ? {
                    oncreate : m.route.link,
                    href     : "/login"
                } :
                false,
            logout : globalState.loggedIn ? {
                    href : "/logout",
                    onclick(e) {
                        e.preventDefault();
                        globalState.logout();
                    }
                } :
                false,
            register : !globalState.loggedIn && !register ? {
                    oncreate : m.route.link,
                    href     : "/register"
                } :
                false
        });
    }
};
