import m       from "mithril";
import { sub } from "template";

import a from "../../../components/anchor";

import globalState from "../../../state";

import css from "../index.css";

const i18n = globalState.i18n["password-reset"];

export default {
    view() {
        const email = m.route.param("email") || "";

        return m("div", { class : css.passwordResetModule },
            m("h3", globalState.i18n.success),

            email ?
                [
                    m("p", sub(i18n.success, { email : email })),
                    m("p",
                        i18n.expirynote,

                        m(a, {
                            href : "/password-reset"
                        }, i18n.retry)
                    )
                ] :
                m("p", globalState.i18n.changepw.success),

            m(a, {
                    class : [ "pure-button", "pure-button-primary", css.passwordResetBtn ].join(" "),
                    href  : `/login?email=${email}`
                },
                i18n.returnlink
            )
        );
    }
};
