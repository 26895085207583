import m from "mithril";

import formLogo from "../../../../../components/form-logo";

import handleForm from "../../../../../util/handle-form";

import tfaSidebar        from "../tfa-sidebar";
import passthroughParams from "../passthrough-params";

import globalState from "../../../../../state";

import css from "../../../index.css";

const defaultAttrs = {
    class        : css.otp,
    type         : "text",
    name         : "otp",
    placeholder  : "#####",
    maxlength    : "5",
    size         : "5",
    pattern      : "[0-9]{5}",
    autocomplete : "one-time-code",
    oncreate({ dom }) {
        dom.focus();
    }
};

const totpAttrs = Object.assign({}, defaultAttrs, {
    placeholder : "######",
    maxlength   : "6",
    size        : "6",
    pattern     : "[0-9]{6}"
});

export default {
    oninit : ({ attrs, state }) => {
        state.i18n       = globalState.i18n.wait[attrs.type];
        state.inputAttrs = attrs.type === "totp" ? totpAttrs : defaultAttrs;
    },

    view : ({ attrs, state }) =>
        m("div.module.pure-u-1.pure-u-sm-18-24.pure-g[data-test=email-auth]",
            m(tfaSidebar),

            m("form.pure-form.pure-g.pure-u-1.pure-u-md-2-3", handleForm({
                    action : `/login/wait/${attrs.type}`,
                    method : "post",
                    class  : css.totpForm
                }),
                m(formLogo),

                m(passthroughParams),

                m("div.pure-u-1",
                    m("input[type=hidden][name=_formName]", {
                        value : `wait${attrs.type}`
                    }),

                    m("h2", state.i18n.header),

                    m("p", m.trust(state.i18n.body))
                ),

                m("label.field.pure-u-1",
                    m("input", state.inputAttrs)
                ),

                m("label.field.pure-u-1",
                    m("input[type=checkbox][name=allowlist]"),
                    globalState.i18n.wait.remember
                ),

                m("label.field.pure-u-1",
                    m("button.pure-button.pure-button-primary[type=submit]",
                        globalState.i18n.wait.submit
                    )
                )
            )
        )
};
