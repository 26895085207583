/**
 * Check if user-agent is a 64bit os or not
 * @param {string} userAgent user agent from browser
 * @returns {boolean} if userAgent contains a 64bit string
 */
function is64(userAgent) {
    return [ "x86_64", "x86-64", "win64", "x64;", "amd64", "wow64", "x64_64" ]
        .some(_64String => userAgent.includes(_64String));
}

/**
 * Detect OS from navigator string
 *
 * @typedef {object} OS object with bits and type of current OS
 * @property {string} type string that === "osx" || "win"
 * @property {number} bits bits of os === 32 || 64
 *
 * @returns {OS} os data
 */
export default function detectOs() {
    const nav  = window.navigator || false;
    const type = (nav.platform || "").toLowerCase().includes("mac") ? "osx" : "win";

    return {
        type,
        // if osx or userAgent contains a string from all64, then bits = 64
        bits : type === "osx" || 64
    };
}
