import m from "mithril";

import css from "./index.css";

let img;

export default {
    oninit() {
        m.request({ url : "https://api.guildwars2.com/v2/quaggans/cry" })
            .then(({ url }) => {
                img = url;
            });
    },
    view() {
        return img ? m("div", { class : css.error }, m("img", { src : img, alt : "" })) : null;
    }
};
