"use strict";

const steamRegex = /^[0-9]{17}@steam$/;

/**
 * @param {string} login - Account login (email or {steamid}@steam)
 * @returns {boolean} true if Steam account
 */
module.exports = function isSteam(login = "") {
    return steamRegex.test(login);
};
