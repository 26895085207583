import m from "mithril";

import css from "../../index.css";

export default {
    view : ({ attrs }) =>
        m("iframe", {
            class           : css.video,
            type            : "text/html",
            width           : 503,
            height          : 340,
            frameborder     : 0,
            allowfullscreen : true,
            src             : `//www.youtube.com/embed/${attrs.vidId}?autohide=1&showinfo=0&modestbranding=0`
        })
};
