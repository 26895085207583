import m from "mithril";

import formField from "../../../components/form-field";

import handleForm from "../../../util/handle-form";

import globalState from "../../../state";

import css from "../index.css";

const i18n     = globalState.i18n["password-reset"].recovery;
const formI18n = globalState.i18n.forms["password-reset-recovery"];

export default {
    view : () => {
        const requirements = m.route.param("requirements").split(",");
        const formName     = `password-reset-recovery-${requirements.join("")}`;

        return m("div", { class : css.passwordResetModule },
            m("h3", i18n.heading),

            m("p", i18n.body),

            m("form.pure-form[method=post][action=/password-reset/recovery]",
                handleForm(),

                m(`input[type=hidden][name=_formName][value=${formName}]`),

                m("input[type=hidden][name=s]", { value : m.route.param("s") }),

                m("input[type=hidden][name=email]", {
                    value : m.route.param("email"),
                }),

                requirements.map(requirement =>
                    m(formField, {
                        name       : requirement,
                        labelClass : "pure-u-1 pure-u-md-17-24",
                        strings    : formI18n[requirement],
                    })
                ),

                m("button[type=submit].pure-button.pure-button-primary",
                    { class : css.passwordResetBtn },
                    globalState.i18n.submit
                )
            )
        );
    },
};
