import globalState from "../state";

let loginFired = false;

const queue = [];

export function processQueue() {
    if (!window.uancsoft) {
        // gamesight is loaded after bundle starts running, so isn't always there when the page is hit
        // try again in 5s
        return setTimeout(() => processQueue(), 5000);
    }

    while (queue.length) {
        window.uancsoft(...queue[0]);

        queue.shift();
    }
}

export default function gamesight(...args) {
    if (args[0] === "logout") {
        loginFired = false;

        return;
    }

    // only need to set user and fire login event once
    if (globalState.loggedIn && !loginFired) {
        loginFired = true;

        queue.push([ "set", "user_id", [{ type : "user_id", id : globalState.user.user_id.toUpperCase() }]]);
        queue.push(args);
    } else {
        // exit since login event has already been fired
        if (args[1] === "account_anet_login") {
            return;
        }

        queue.push(args);
    }

    processQueue();
}
