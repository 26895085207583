import m       from "mithril";
import { sub } from "template";

import getDownloadLink from "../../../../util/get-download-link";
import gamesight       from "../../../../util/gamesight";

import globalState from "../../../../state";

import css from "../../index.css";

const gcI18n   = globalState.i18n.gameContent;
const eulaI18n = globalState.i18n.eulaAck;

const button = {
    view({ attrs }) {
        let osKey = attrs.client.os;

        if (attrs.client.test) {
            osKey = `${attrs.client.os}test`;
        } else if (attrs.client.beta) {
            osKey = `${attrs.client.os}beta`;
        }

        return m("a", {
                class       : css[attrs.client.os],
                "data-test" : "downloadClient",
                href        : getDownloadLink(attrs.client),
                onclick() {
                    gamesight("send", "account_anet_download");
                }
            },
            gcI18n.download[osKey],

            m("br"),

            gcI18n.download[`${attrs.client.bits}bit`]
        );
    }
};

/**
 * @type {import("mithril").Component<{ platform: "Steam"|"Epic" }, {}>}
 */
const thirdPartyInstructions = {
    view(vnode) {
        const { platform } = vnode.attrs;

        return [
            m("h3", gcI18n.downloads.thirdParty.title.replace("{platform}", platform)),
            m("p", gcI18n.downloads.thirdParty.p.replace("{platform}", platform)),
            m("a",
                { href : globalState.get(`urls.${platform.toLowerCase()}Install`) },
                gcI18n.downloads.thirdParty.a.replace("{platform}", platform)
            )
        ];
    }
};

/**
 * @type {import("mithril").Component<{ bestClient: {
 *  os: string,
 *  beta: boolean,
 *  test: boolean,
 *  bits: string
 * } }, {}>}
 */
export default {
    view(vnode) {
        const { bestClient } = vnode.attrs;

        if (globalState.isSteamUser) {
            return m(thirdPartyInstructions, { platform : "Steam" });
        }

        if (globalState.isEpicUser) {
            return m(thirdPartyInstructions, { platform : "Epic" });
        }

        return [
            m("h3", gcI18n.downloads.title),

            // "Game Client - NA & EU"
            m("div", { class : css.clientInfo },
                m("span", { class : css.dlTitle },
                    gcI18n.download.client,
                    " - "
                ),
                gcI18n.download.location
            ),

            // client download link
            m(button, { client : bestClient }),

            // EULA Ack
            m("p", { class : css.eulaAck },
                m.trust(sub(eulaI18n, { downloadText : gcI18n.download.win }))
            )
        ];
    }
};
