import m       from "mithril";
import { sub } from "template";

import getSteamId from "getSteamId";
import getEpicId  from "getEpicId";

import globalState from "../../state";

import css from "./index.css";

const i18n = globalState.i18n.login.error.thirdParty.support;

/**
 * @type {import("mithril").Component<{
 *  platform: "steam"|"epic"
 * }, {
 *  casedPlatform: string,
 *  platformId: string,
 *  displayName: string,
 *  emailParams: {
 *      subject: string,
 *      body: string
 *  },
 *  supportEmail: string
 * }}
 */
export default {
    oninit({ attrs, state }) {
        const { platform } = attrs;

        state.casedPlatform = platform === "steam" ? "Steam" : "Epic";

        state.platformId  = platform === "steam" ?
            getSteamId(globalState.user.login_name) :
            getEpicId(globalState.user.login_name);
        state.displayName = globalState.user.user_name;
        state.emailParams = m.buildQueryString({
            subject : `${state.casedPlatform} support request`,
            body :
`${state.casedPlatform} ID: ${state.steamId}
Display name: ${state.displayName}
Support ID: ${globalState.user.user_id}`
        });
        /* eslint-disable-next-line max-len */
        state.supportEmail = `<a href='mailto:${globalState.supportEmail}?${state.emailParams}'>${decodeURIComponent(globalState.supportEmail)}</a>`;
    },

    view({ state }) {
        return m("div.module.pure-u-1.pure-u-md-19-24",
            m("h3", i18n.header.replace("{platform}", state.casedPlatform)),
            m("p", m.trust(sub(i18n.p, { supportEmail : state.supportEmail }))),

            m("ul", { class : css.list },
                m("li", i18n.li.platformId.replace("{platform}", state.casedPlatform), state.platformId),
                m("li", i18n.li.displayName, state.displayName),
                m("li", i18n.li.supportId, globalState.user.user_id)
            )
        );
    }
};
