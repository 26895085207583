import m from "mithril";

import layout from "./components/layout";

import addCode      from "./components/modals/add-code";
import changePw     from "./components/modals/change-pw";
import linkAccounts from "./components/modals/link-accounts";
import pii          from "./components/modals/pii";

import login      from "./pages/login";
import loginWait  from "./pages/login/wait";
import loginAllow from "./pages/login/allow";

import pwReset         from "./pages/password-reset";
import pwResetAuth     from "./pages/password-reset/auth";
import pwResetRecovery from "./pages/password-reset/recovery";
import pwResetConfirm  from "./pages/password-reset/confirm";
import pwResetSuccess  from "./pages/password-reset/success";
import pwResetError    from "./pages/password-reset/error";

import verify            from "./pages/verify";
import register          from "./pages/register";
import welcome           from "./pages/welcome";
import overview          from "./pages/overview";
import settings          from "./pages/settings";
import thirdPartySupport from "./pages/third-party-support";

import allowlist        from "./pages/allowlist";
import allowlistSuccess from "./pages/allowlist/success";
import allowlistError   from "./pages/allowlist/error";

import securitySettings  from "./pages/security/settings";
import email             from "./pages/security/email";
import emailConfirm      from "./pages/security/email/confirm";
import finishLater       from "./pages/security/2-step/finish-later";
import smsStepVerify     from "./pages/security/2-step/sms/step-verify";
import smsStepLink       from "./pages/security/2-step/sms/step-link";
import smsUnlinkWarning  from "./pages/security/2-step/sms/unlink/step-warning";
import smsUnlinkRemove   from "./pages/security/2-step/sms/unlink/step-remove";
import twoStepLinkDone   from "./pages/security/2-step/link/step-done";
import twoStepUnlinkDone from "./pages/security/2-step/unlink/step-done";
import totpStepLink      from "./pages/security/2-step/totp/step-link";
import totpStepDownload  from "./pages/security/2-step/totp/step-download";
import totpUnlinkWarning from "./pages/security/2-step/totp/unlink/step-warning";
import totpUnlinkRemove  from "./pages/security/2-step/totp/unlink/step-remove";

import content from "./pages/content";

import applications       from "./pages/applications";
import applicationsEdit   from "./pages/applications/edit";
import applicationsCreate from "./pages/applications/create";
import applicationsDelete from "./pages/applications/delete";

import oauth                    from "./pages/oauth";
import oauthAccountLinkComplete from "./pages/oauth/account-link-complete";

import unsubscribe from "./pages/unsubscribe";
import subscribed  from "./pages/subscribed";

import page404 from "./pages/404";

import "./util/mailcheckdomains";

import syncCid   from "./util/sync-cid";
import ga        from "./util/ga";
import gamesight from "./util/gamesight";

import globalState from "./state";

import "./index.css";

const modalMap = {
    "add-code"      : addCode,
    "change-pw"     : changePw,
    "link-accounts" : linkAccounts,
    name            : pii,
    phone           : pii
};

const meta = document.querySelector("meta[name='description']");

let first = true;

function r(page, attrs) {
    /* program:!live */
    window.globalState = window.globalState || globalState;
    /* /program:!live */

    return {
        onmatch(...args) {
            const queryParams = args[0];

            if (!queryParams[404] && !globalState.redirect) {
                globalState.updateState({ first })
                    .then(() => {
                        // doing this here and not onSuccess in the login form because user_id is not available until the redirect
                        gamesight("send", "account_anet_login");
                    });
            }

            if (globalState.loggedIn && !globalState.isPreloaded() && !globalState.redirect) {
                globalState.preloadPages();
            }

            // if there are any visible error messages, remove them
            // Steam logins might have errors on first load
            if (globalState.errorMessage && !first) {
                globalState.clearErrorMessage();
            }

            if (page.onmatch) {
                page.onmatch(...args);
            }

            document.title = page.title || "ArenaNet";
            meta.setAttribute("content", page.description || "");

            globalState.setDefaults();

            // only once
            if (first) {
                first = false;

                syncCid(queryParams.cid);
            }

            ga({
                event : "gaPageView",
                page  : m.route.get() || window.location.pathname
            });
        },
        render : () => m(layout, m(page, attrs))
    };
}

function resolveToRoute(route) {
    return {
        onmatch() {
            m.route.set(route);
        }
    };
}

m.route.prefix("");

m.route(document.getElementById("mount"), "/login", {
    /* ******* LOGIN ******* */
    ""  : resolveToRoute("/login"),
    "/" : resolveToRoute("/login"),

    "/login"                 : r(login),
    "/login/wait/:auth_type" : r(loginWait),
    "/login/allow"           : r(loginAllow),
    // maybe overload the /login route, but would need to fork trolls based on params or referrer
    "/login/steam/return"    : globalState.get("features.steam") ? r(login) : undefined,

    /* ******* PASSWORD RESET ******* */
    "/password-reset"                 : r(pwReset),
    "/password-reset/auth/:auth_type" : r(pwResetAuth),
    "/password-reset/recovery"        : r(pwResetRecovery),
    "/password-reset/confirm"         : r(pwResetConfirm),
    "/password-reset/success"         : r(pwResetSuccess),
    "/password-reset/error"           : r(pwResetError),

    "/recovery"      : r(pwReset),
    "/verify"        : r(verify),
    "/register"      : r(register),
    "/welcome"       : r(welcome),
    "/overview"      : r(overview),
    "/settings"      : r(settings),
    "/steam-support" : r(thirdPartySupport, { platform : "steam" }),
    "/epic-support"  : r(thirdPartySupport, { platform : "epic" }),

    /* ******* allowlist ******* */
    "/allowlist"            : resolveToRoute("/allowlist-ip"),
    "/allowlist-ip"         : r(allowlist),
    "/allowlist-ip/success" : r(allowlistSuccess),
    "/allowlist-ip/error"   : r(allowlistError),

    /* ******* SECURITY ******* */
    "/security"                   : r(securitySettings),
    "/security/settings"          : r(securitySettings),
    "/security/email"             : r(email),
    "/security/email/confirm"     : r(emailConfirm),
    "/security/2-step"            : r(smsStepVerify),
    "/security/2-step/later"      : r(finishLater),
    "/security/2-step/sms/verify" : r(smsStepVerify),
    "/security/2-step/sms/link"   : r(smsStepLink),
    "/security/2-step/sms/done"   : r(twoStepLinkDone, { type : "sms" }),

    /* ******* TOTP SIGNUP FLOW ******* */
    "/security/2-step/totp/download" : r(totpStepDownload),
    "/security/2-step/totp/link"     : r(totpStepLink),
    "/security/2-step/totp/done"     : r(twoStepLinkDone, { type : "totp" }),

    /* ******* TOTP UNLINK FLOW ******* */
    "/security/2-step/totp/unlink/warning" : r(totpUnlinkWarning),
    "/security/2-step/totp/unlink/remove"  : r(totpUnlinkRemove),
    "/security/2-step/totp/unlink/done"    : r(twoStepUnlinkDone, { type : "totp" }),

    /* ******* SMS UNLINK FLOW ******* */
    "/security/2-step/sms/unlink/warning" : r(smsUnlinkWarning),
    "/security/2-step/sms/unlink/remove"  : r(smsUnlinkRemove),
    "/security/2-step/sms/unlink/done"    : r(twoStepUnlinkDone, { type : "sms" }),

    /* ******* MODAL ******* */
    "/modals/:modal" : {
        onmatch({ modal }) {
            globalState.modal.display(modalMap[modal], { name : modal });
            m.route.set("/overview");
        }
    },

    /* ******* CONTENT ******* */
    "/content" : r(content),

    /* ******* APPLICATIONS ******* */
    "/applications"            : r(applications),
    "/applications/create"     : r(applicationsCreate),
    "/applications/:id/edit"   : r(applicationsEdit),
    "/applications/:id/delete" : r(applicationsDelete),

    /* ******* OAUTH ******* */
    "/oauth2/authorization"         : r(oauth),
    "/oauth2/init-account-linking"  : r(oauth),
    "/oauth2/link-accounts"         : r(oauth),
    "/oauth2/account-link-complete" : r(oauthAccountLinkComplete),

    /* ******* NEWSLETTER ******* */
    "/unsubscribe" : r(unsubscribe),
    "/subscribed"  : r(subscribed),

    "/:404..." : r(page404)
});
