import m from "mithril";

import globalState from "../../../state";

import css from "../index.css";

export default {
    view() {
        const gcI18n = globalState.i18n.gameContent;

        return globalState.remoteRights !== "None" ?
            m("div.pure-u-1.pure-u-md-19-24", { class : css.featuresModule },

                m("h3", `${globalState.gameCode} ${gcI18n.features}`),
                m("table", { class : "table" },
                    m("thead",
                        m("tr",
                            m("th", "id"),
                            m("th", "count"),
                            m("th", "max"),
                            m("th", "name")
                        )
                    ),
                    m("tbody",
                        globalState.accountFeatures.map(({ id, count, max_count, name }) =>
                            m("tr",
                                m("td", id),
                                m("td", count),
                                m("td", max_count),
                                m("td", name)
                            )
                        )
                    )
                )
            ) :
            null;
    }
};
