import m         from "mithril";
import cssjoiner from "cssjoiner";

import globalState from "../../../state";

import qr from "../../../components/qr";
import a  from "../../../components/anchor";

import header from "../components/header";

import css from "../index.css";

const apI18n = globalState.i18n.apikeys;

export default {
    oninit({ state }) {
        state.qr = false;
    },

    view({ state }) {
        const keys = globalState.keys;

        return [
            m("div[data-test=create-text].pure-u-1",
                m(header, { header : apI18n.index.header, desc : apI18n.index.desc })
            ),

            m("div.module.pure-u-1", { class : css.module },
                m(a, {
                        "data-test" : "create-button",
                        href        : "/applications/create",
                        class       : keys.length >= globalState.maxKeysPerUser ?
                            "pure-button-disabled" :
                            css.newKey,
                        onclick : keys.length < globalState.maxKeysPerUser ? undefined : () => false
                    },
                    apI18n.index.list.new
                ),

                m("h3", apI18n.index.list.header),

                m("div",
                    keys.length ?
                        keys.map(key => {
                            const keyLink = `/applications/${key.id}`;

                            return m("div", { class : css.apiKey, key : key.id },
                                m("h4", [
                                    m("span[data-test=api-key]", { class : css.keyName }, decodeURIComponent(key.name)),

                                    m(a, {
                                        href  : `${keyLink}/edit`,
                                        class : css.subtext,
                                    }, apI18n.index.list.edit),

                                    m(a, {
                                        href  : `${keyLink}/delete`,
                                        class : css.subtext,
                                    }, apI18n.index.list.delete)
                                ]),

                                m("div", { class : css.keyContainer },
                                    m("input", {
                                        class    : css.keyCode,
                                        style    : { opacity : key.mask ? 0 : 1 },
                                        id       : `key-${key.id}`,
                                        value    : key.key,
                                        type     : key.dontHide ? "text" : "password",
                                        readonly : true,

                                        onclick({ currentTarget }) {
                                            currentTarget.setSelectionRange(
                                                0,
                                                currentTarget.value.length
                                            );
                                        }
                                    }),

                                    m("div", { class : css.actions },
                                        // show/hide key
                                        m("button[data-test=key-visibility]", {
                                            class : css.keyEye,
                                            onclick() {
                                                key.dontHide = !key.dontHide;

                                                // hide again after 3 sec
                                                if (key.dontHide) {
                                                    key.timeout = setTimeout(() => {
                                                        delete key.dontHide;
                                                        m.redraw();
                                                    }, 3000);
                                                } else {
                                                    // or don't if already hidden
                                                    clearTimeout(key.timeout);
                                                }
                                            }
                                        }),

                                        // copy key
                                        m("button[data-test=copy-key]", {
                                            // ".key-copy" needed for clipboard delegate
                                            class : cssjoiner(css.keyCopy, "key-copy"),

                                            // what element to copy when clicked
                                            "data-clipboard-target" : `#key-${key.id}`,

                                            // need to unhide (type text, not password) so that clipboard can copy
                                            onclick() {
                                                const oldVal = key.dontHide;

                                                key.dontHide = true;
                                                key.mask     = !oldVal;

                                                // hide again after copying
                                                setTimeout(() => {
                                                    key.dontHide = oldVal;
                                                    delete key.mask;
                                                    m.redraw();
                                                }, 20);
                                            }
                                        }),

                                        // key QR code
                                        m("button[data-test=key-qr]", {
                                            class : css.qrBtn,
                                            onclick() {
                                                state.qr = state.qr !== key.key ? key.key : false;
                                            }
                                        })
                                    ),

                                    // QR code img
                                    state.qr === key.key ?
                                        m("div", { class : css.key }, m(qr, { value : state.qr })) :
                                        null
                                ),
                                // permissions
                                m("ul", { class : css.keyPermissions },
                                    key.scopes.map(scope =>
                                        m("li", { class : css.permWithGear },
                                            m("abbr", { title : apI18n.scopes[scope] || "" }, scope)
                                        )
                                    ))
                            );
                        }) :
                        m("div.no-keys", apI18n.index.list.empty)
                )
            )
        ];
    }
};
