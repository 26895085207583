import m from "mithril";

import a from "../../../components/anchor";

import globalState from "../../../state";

import css from "./index.css";

const i18n = globalState.i18n;

export default {
    oninit(vnode) {
        vnode.state.bodyTxt = i18n.oauth2.linkingComplete.body.split("{Applications}");
    },

    view(vnode) {
        return m(".module.pure-u-1.pure-u-sm-1-2",
            { "data-test" : "account-linking-success" },
            m("h2", i18n.oauth2.linkingComplete.header),

            m("p", { class : css.p }, vnode.state.bodyTxt[0]),
            m(a, { href : "/applications" }, i18n.nav.applications),
            m("p", { class : css.p }, vnode.state.bodyTxt[1])
        );
    }
};
