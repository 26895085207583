"use strict";

function _path(path) {
    if (typeof path === "string") {
        path = path.split(".");
    }

    return path;
}

exports.set = function(obj, path, val) {
    let ref = obj,
        i;

    path       = _path(path);
    const size = path.length - 1;

    if (size >= 0) {
        for (i = 0; i < size; i++) {
            // Create sub-objects if necessary
            if (!(path[i] in ref)) {
                ref[path[i]] = {};
            }

            ref = ref[path[i]];
        }

        ref[path[i]] = val;
    }

    return obj;
};

exports.get = function(obj, path) {
    if (typeof obj !== "object") {
        return undefined;
    }

    path = _path(path);
    const size = path.length;

    for (let i = 0; obj !== undefined && obj !== null && i < size; i++) {
        obj = obj[path[i]];
    }

    return obj;
};

exports.merge = function() {
    const size = arguments.length;
    const out  = {};

    for (let idx = 0; idx < size; idx++) {
        const obj = arguments[idx];

        for (const key in obj) {
            if (!obj.hasOwnProperty(key)) {
                continue;
            }

            out[key] = obj[key];
        }
    }

    return out;
};

exports.each = function(obj, fn) {
    let idx = 0;

    for (const key in obj) {
        if (!obj.hasOwnProperty(key)) {
            continue;
        }

        fn(obj[key], key, idx++);
    }
};
